import styled from "styled-components";
import { styles } from "../styles/styles";

export const PageWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 320px);
  /* display: flex;
  flex-direction: column; */
  @media only screen and (max-width: 576px) {
    min-height: calc(100vh - 100px);
  }
`;

export const TitlePart = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  @media only screen and (max-width: 1050px) {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 720px) {
    display: none;
  }
`;

export const GoBack = styled.div`
  background: #eee7e1;
  border-radius: 18px;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  padding: 9px 21px;
  margin-right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;

  letter-spacing: -1px;

  color: #000000;
`;

export const Body = styled.div`
  /* margin: 40px 0; */
  min-height: calc(100% - 80px);
  @media only screen and (max-width: 576px) {
    min-height: 100%;
  }
`;

export const SwitchToAgentBtn = styled.div`
  padding: 8px 27px;
  margin-left: auto;
  background-color: ${styles.mainColor};
  color: white;
  border-radius: 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`;
