import NumberFormat from "react-number-format";
import styled from "styled-components";
import { styles } from "../styles/styles";
import InputPhoneNumber from "react-phone-number-input/input";

export const FormWrapper = styled.div`
  width: 320px;
  margin: auto;
`;

export const FormTitle = styled.p`
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 16px;
  @media only screen and (max-width: 350px) {
    font-size: 32px;
    line-height: 36px;
  }
`;

export const FormDesc = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 32px;
`;

export const NumberFormatInput = styled(NumberFormat)`
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  padding: 15px 24px;
  border-radius: 10px;
  border: 1px solid rgba(147, 147, 147, 1);
  outline: 0;

  &:focus {
    border-color: rgba(254, 200, 38, 1);
  }
`;

export const Button = styled.button`
  width: ${({ width, fit }: { width?: string; fit?: boolean }) =>
    fit ? "fit-content" : width ? width : "100%"};
  /* height: 32px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${styles.mainColor};
  color: white;
  border-radius: 10px;
  font-size: 16px;
  border: 0;
  padding: 18px 45px;
  cursor: pointer;
  transition-duration: 5ms;
  margin: auto;
  &:hover:not(:disabled) {
    opacity: 0.9;
  }
  &:disabled {
    background-color: #cccccc;
    color: #666666;
  }
`;

export const Input = styled.input`
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  padding: 15px 24px;
  border-radius: 10px;
  border: 1px solid rgba(147, 147, 147, 1);
  outline: 0;

  &:focus {
    border-color: rgba(254, 200, 38, 1);
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  &:focus-within {
    label {
      color: ${styles.mainColor};
    }
    &::after {
      background-color: ${styles.mainColor};
      height: 2px;
    }
  }
  label {
    display: block;
    width: fit-content;
    color: #2222227f;
    /* mix-blend-mode: normal; */
    /* opacity: 0.5; */
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    span.required {
      color: ${styles.mainColor};
    }
  }
  div {
    display: flex;
    align-items: center;
    img {
      width: 22px;
      height: fit-content;
      margin-right: 7px;
    }
  }
  input,
  textarea {
    display: block;
    width: 100%;
    border: 0;
    padding: 6px 0;
    position: relative;
    outline: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    &::placeholder {
      color: rgba(0, 0, 0, 0.38);
    }
    resize: none;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.12);
  }
`;

export const InputPNWrapper = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* padding: 15px 24px; */
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid rgba(147, 147, 147, 1);
  outline: 0;
  display: flex;
  cursor: pointer;

  select {
    border: 0;
    padding-left: 15px;
    outline: none;
  }

  &:focus-within {
    outline: none;
    border-color: rgba(254, 200, 38, 1);
  }
`;
export const InputPN = styled(InputPhoneNumber)`
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  padding: 15px 24px 15px 10px;
  /* border-radius: 10px; */
  /* border: 1px solid rgba(147, 147, 147, 1); */
  border: 0;
  outline: 0;

  &:focus {
    border-color: rgba(254, 200, 38, 1);
  }
`;