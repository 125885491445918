import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .row1 {
    width: 30%;
  }
  .row2 {
    width: 40%;
  }
  .row3 {
    width: 30%;
    padding: 80px 0 0 25px;
  }
  @media only screen and (max-width: 1050px) {
    .row1 {
      width: 0%;
    }
    .row2{
      width: 55%;
    }
    .row3{
      width: 45%;
      padding-top: 60px;
    }
  }
  @media only screen and (max-width: 720px) {
    flex-direction: column-reverse;
    .row1 {
      width: 0%;
    }
    .row2{
      width: 100%;
    }
    .row3{
      width: 90%;
      max-width: 350px;
      /* padding-top: 60px; */
      padding: 0;
      /* padding-top: 20px; */
      padding-bottom: 20px;
      margin: auto;
    }

  }
`;


export const LoginWrapper = styled.div`
  min-height: calc(100vh - 400px);
  background-color: white;
  padding: 80px 0;

  @media only screen and (max-width: 576px){
    min-height: calc(100vh - 0px);
  }
`;

export const FormWrapper = styled.div`
  width: 320px;
  margin: 0px auto;
  @media only screen and (max-width: 350px){
    width: 100%;
    padding: 0 20px;
  }
`;

