import styled from "styled-components";
import { styles } from "../../components/styles/styles";

export const RegistrationWrapper = styled.div`
  min-height: calc(100vh - 400px);
  background-color: white;
  padding: 80px 0;

  @media only screen and (max-width: 576px){

  }
`;

export const FormWrapper = styled.div`
  width: 320px;
  margin: 0px auto;
  @media only screen and (max-width: 350px){
    width: 100%;
    padding: 0 20px;
  }

`;

export const InputWrapper = styled.div`
  margin: 20px 0;
`;

export const PhoneNumberBox = styled.div`
  background-color: rgba(239, 239, 239, 0.3);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 6px 0;
`;

export const UserTypeBox = styled.div`
  width: 100%;
  display: flex;
  margin-top: 6px;
  .box {
    width: 50%;
    padding: 10px;
    text-align: center;
    border-top: 1px solid lightgrey;
    font-weight: bold;
    cursor: pointer;
    justify-content: center;

    &:first-child {
      border-left: 1px solid lightgrey;
    }
    &:last-child {
      border-right: 1px solid lightgrey;
    }
  }
  .active {
    background-color: ${styles.mainColor};
    color: white;
  }
  .line {
    width: 1px;
    height: 43px;
    background-color: lightgrey;
  }
`;

export const CityList = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 55px;
  width: 100%;
  height: fit-content;
  min-height: 0;
  background-color: white;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  z-index: 4;
  border-radius: 4px;
  -webkit-box-shadow: 0 3px 6px -1px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0 3px 6px -1px rgba(34, 60, 80, 0.2);
  box-shadow: 0 3px 6px -1px rgba(34, 60, 80, 0.2);

  .item {
    border-bottom: 1px solid #ebebeb;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    b {
      margin-right: 5px;
    }

    &:hover {
      background-color: #ebebeb;
    }
  }

  .not-found {
    width: 100%;
    height: 100px;
    background-color: lightblue;
  }
`;