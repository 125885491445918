import React, {memo, useEffect, useState} from "react";
import {InputBox} from "../../components/form";
import {Button} from "../../components/form/styles";
import {GoBack} from "../../components/pageContainer/styles";
import {ContentTitle} from "../../components/styles/styles";
import {
  DocItem,
  DocsListWrapper,
  DocTitle,
  DocumentsWrapper,
  EditVehicleWrapper,
  FormGroup,
  FormWrapper,
} from "./styles";

import "./select-custom.css";

import {ReactComponent as LeftArrow} from "../../assets/icons/left-arrow.svg";
import {ReactComponent as RightArrow} from "../../assets/icons/arrow-right.svg";
import {ReactComponent as UserHead} from "../../assets/icons/user-head.svg";
import {ReactComponent as Case} from "../../assets/icons/case.svg";
import VehiclePhotoPopUp from "../../components/popUp/vehiclePhotoPopUp";
import {Select} from "antd";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {
  clearSelectedVehicle,
  fetchAddVehicles,
  fetchUpdateVehicle,
  getVehicleById,
  getVehicleCategories,
  removePassportImages,
  removeVehicleById,
  removeVehicleImages,
  uploadPassportImages,
  uploadVehicleImages,
} from "../../redux/store/reducers/vehicleSlice";

import {RootState} from "../../redux/store/store";
import {getColorPhotoStatus, getPhotoStatus} from "../../utils/uiHelpers";

const {Option} = Select;

interface VehicleForm {
  categoryId: number | string;
  brand: string;
  model: string;
  stateNumber: string;
  comment: string;
  photoState?: string;
  passportState?: string;
}

const initialState = {
  categoryId: "",
  brand: "",
  model: "",
  stateNumber: "",
  comment: "",
};

const EditVehiclePage: React.FC<any> = ({path: id, setPath}) => {
  // const navigate = useNavigate();
  const myDispatch = useAppDispatch();
  // const {id} = useParams();
  const [errorPhoto, setErrorPhoto] = useState<string>("");

  const {user} = useAppSelector((state: RootState) => state.auth);
  const vehicleCategories = useAppSelector(
      (state: RootState) => state.vehicles.vehicleCategories
  );
  const selectedVehicle = useAppSelector(
      (state: RootState) => state.vehicles.selectedVehicle
  );
  const addStatus = useAppSelector(
      (state: RootState) => state.vehicles.addStatus
  );

  const loadingImages = useAppSelector(
      (state: RootState) => state.vehicles.loadingImages
  );

  const loadingPassport = useAppSelector(
      (state: RootState) => state.vehicles.loadingPassport
  );

  const [vehicleForm, setVehicleForm] = useState<VehicleForm>(initialState);

  const [vehiclePhotoPopUp, setVehiclePhotoPopUp] = useState({
    popUp: false,
    data: {},
  });
  const [stsPopUp, setStsPopUp] = useState({popUp: false, data: {}});

  useEffect(() => {
    if (user && user.customer?.refId) {
      if (id && id !== "add")
        myDispatch(
            getVehicleById({customerId: user.customer?.refId, vehicleId: id})
        );
      myDispatch(getVehicleCategories());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleSubmit = (files: any) => {
    const formData = new FormData();
    files.forEach((element: any, index: number) => {
      formData.append(`file${index}`, element.file);
    });
    if (id && id !== "add")
      myDispatch(
          uploadVehicleImages({
            customerId: user.customer?.refId,
            vehicleId: id,
            data: formData,
          })
      );
  };

  useEffect(() => {
    if (loadingImages === "succeeded" && id) {
      setVehiclePhotoPopUp({popUp: false, data: {}});
      myDispatch(
          getVehicleById({customerId: user.customer?.refId, vehicleId: id})
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingImages]);

  useEffect(() => {
    if (loadingPassport === "succeeded" && id) {
      setStsPopUp({popUp: false, data: {}});
      myDispatch(
          getVehicleById({customerId: user.customer?.refId, vehicleId: id})
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPassport]);

  const removePhoto = async (customerId: string, vehicleId: string) => {
    await myDispatch(removeVehicleImages({customerId, vehicleId}));
  };

  const handleSubmitSts = (files: any) => {
    const formData = new FormData();
    files.forEach((element: any, index: number) => {
      formData.append(`file${index}`, element.file);
    });
    if (id && id !== "add")
      myDispatch(
          uploadPassportImages({
            customerId: user.customer?.refId,
            vehicleId: id,
            data: formData,
          })
      );
  };

  const removePhotoSts = async (customerId: string, vehicleId: string) => {
    await myDispatch(removePassportImages({customerId, vehicleId}));
  };

  const onChangeSelect = (value: any) => {
    setVehicleForm((prevState: any) => ({
      ...prevState,
      // eslint-disable-next-line no-useless-computed-key
      ["categoryId"]: value,
    }));
  };

  const onChangeInput = (e: React.MouseEvent<HTMLInputElement>) => {
    const {name, value} = e.currentTarget;
    setVehicleForm((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSearch = (value: any) => {
    console.log("search:", value);
  };

  const handleSubmitData = () => {
    if (id && id !== "add")
      myDispatch(
          fetchUpdateVehicle({
            customerId: user.customer?.refId,
            vehicleId: id,
            data: vehicleForm,
          })
      );
    else
      myDispatch(
          fetchAddVehicles({
            customerId: user.customer?.refId,
            data: vehicleForm,
          })
      );
  };

  useEffect(() => {
    if (addStatus === "succeeded") setPath("list");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);

  const handleRemove = () => {
    if (id && id !== "add")
      myDispatch(
          removeVehicleById({customerId: user.customer?.refId, vehicleId: id})
      );
  };

  useEffect(() => {
    if (id && id !== "add" && selectedVehicle) setVehicleForm(selectedVehicle);
  }, [selectedVehicle, id]);

  useEffect(() => {
    return () => {
      myDispatch(clearSelectedVehicle());
      setVehicleForm(initialState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (id && id !== "add" && selectedVehicle === null)
    return <div>Загрузка...</div>;

  if (!user) return <div></div>;

  return (
      <EditVehicleWrapper>
        <ContentTitle>
          <GoBack onClick={() => setPath("list")}>
            <LeftArrow/> Назад
          </GoBack>
          {id === "add" ? "Добавить технику" : "Редактирование"}
        </ContentTitle>
        <FormWrapper>
          <label
              style={{
                color: "#2222227f",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "20px",
              }}
          >
            Тип техники
          </label>
          <Select
              className="container-test"
              showSearch
              placeholder="Строительная техника"
              optionFilterProp="children"
              onChange={onChangeSelect}
              onSearch={onSearch}
              filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
              }
              value={vehicleForm.categoryId}
              bordered={false}
              disabled={addStatus === "pending"}
          >
            {vehicleCategories.map((item: any, index: any) => {
              return (
                  <Option
                      value={item.id}
                      key={`opt${index}`}
                      style={{
                        marginBottom: "0px",
                        width: "100%",
                        // paddingLeft: "0px",
                      }}
                  >
                    {item.title}
                  </Option>
              );
            })}
          </Select>
          <InputBox
              name="brand"
              onChange={onChangeInput}
              label="Марка"
              placeholder="Volvo"
              value={vehicleForm.brand}
              disabled={addStatus === "pending"}
          />
          <InputBox
              name="model"
              onChange={onChangeInput}
              label="Модель технического средства"
              placeholder="Komatsu PC300-7"
              value={vehicleForm.model}
              disabled={addStatus === "pending"}
          />
          <InputBox
              name="stateNumber"
              onChange={onChangeInput}
              label="Государственный номер средства"
              placeholder="P432YK 199"
              value={vehicleForm.stateNumber}
              disabled={addStatus === "pending"}
          />
          <InputBox
              name="comment"
              onChange={onChangeInput}
              label="Примечание"
              placeholder="Добавить примечание"
              value={vehicleForm.comment}
              disabled={addStatus === "pending"}
          />
          <FormGroup>
            {id && id !== "add" && (
                <Button
                    fit
                    onClick={handleRemove}
                    disabled={addStatus === "pending"}
                >
                  Удалить
                </Button>
            )}
            <Button
                fit
                onClick={handleSubmitData}
                disabled={addStatus === "pending"}
            >
              {id && id === "add" ? "Сохранить" : "Обновить"}
            </Button>
          </FormGroup>

          <DocumentsWrapper>
            <DocTitle>
              Документы:{" "}
              {errorPhoto !== "" && (
                  <span style={{color: "red", fontWeight: "normal"}}>
                {errorPhoto}
              </span>
              )}
            </DocTitle>
            <DocsListWrapper>
              <DocItem
                  onClick={() => {
                    if (id && id !== "add")
                      setVehiclePhotoPopUp({popUp: true, data: {}});
                    else setErrorPhoto("Сначала добавьте технику");
                  }}
              >
                <div className="icon">
                  <UserHead/>
                </div>
                <div className="data">
                  <div className="title">Фотография техники</div>
                  <div className="subtitle">
                    {/* Паспорт  */}
                    <span
                        className={
                            vehicleForm &&
                            vehicleForm.photoState &&
                            getColorPhotoStatus(vehicleForm.photoState)
                        }
                    >
                    {getPhotoStatus(vehicleForm.photoState)}
                  </span>
                  </div>
                </div>
                <div className="arrow-right">
                  <RightArrow/>
                </div>
              </DocItem>
              <DocItem
                  onClick={() => {
                    if (id && id !== "add") setStsPopUp({popUp: true, data: {}});
                    else setErrorPhoto("Сначала добавьте технику");
                  }}
              >
                <div className="icon">
                  <Case/>
                </div>
                <div className="data">
                  <div className="title">СТС</div>
                  <div className="subtitle">
                    {/* Пластиковое удостоверение{" "} */}
                    <span
                        className={
                            vehicleForm &&
                            vehicleForm.passportState &&
                            getColorPhotoStatus(vehicleForm.passportState)
                        }
                    >
                    {getPhotoStatus(vehicleForm.passportState)}
                  </span>
                  </div>
                </div>
                <div className="arrow-right">
                  <RightArrow/>
                </div>
              </DocItem>
              {/* <DocItem onClick={() => setDocPopUp({ popUp: true, data: {} })}>
              <div className="icon">
                <Speedometer />
              </div>
              <div className="data">
                <div className="title">Допуск к работам</div>
                <div className="subtitle">
                  Для гарантии безопасности{" "}
                  <span className="rejected">Проверка не пройдена</span>
                </div>
              </div>
              <div className="arrow-right">
                <RightArrow />
              </div>
            </DocItem> */}
            </DocsListWrapper>
          </DocumentsWrapper>
        </FormWrapper>
        {/* {docPopUp.popUp && (
        <VehicleDocPopUp
          close={() => setDocPopUp({ popUp: false, data: {} })}
          onSubmit={() => setDocPopUp({ popUp: false, data: {} })}
        />
      )} */}
        {id && vehiclePhotoPopUp.popUp && (
            <VehiclePhotoPopUp
                close={() => setVehiclePhotoPopUp({popUp: false, data: {}})}
                onSubmit={handleSubmit}
                removePhoto={removePhoto}
                customerId={user.customer?.refId}
                vehicleId={id}
                stateName="vehicleImages"
                rejectReason={selectedVehicle.rejectPhotoReason}
                documentStatus={selectedVehicle.photoState}
            />
        )}
        {id && stsPopUp.popUp && (
            <VehiclePhotoPopUp
                close={() => setStsPopUp({popUp: false, data: {}})}
                onSubmit={handleSubmitSts}
                removePhoto={removePhotoSts}
                customerId={user.customer?.refId}
                vehicleId={id}
                stateName="passportImages"
                rejectReason={selectedVehicle.rejectPassportReason}
                documentStatus={selectedVehicle.passportState}
            />
        )}

        {/* {id && stsPopUp.popUp && (
        <StsPopUp
          close={() => setStsPopUp({ popUp: false, data: {} })}
          onSubmit={() => setStsPopUp({ popUp: false, data: {} })}
        />
      )} */}
      </EditVehicleWrapper>
  );
};

export default memo(EditVehiclePage);
