import React from "react";
import PopUpContainer from "../popUpContainer";

const UserAgreementPopUp: React.FC<{ close: any }> = ({ close }) => {
  return (
    <PopUpContainer big close={close}>
      <div style={{ padding: "0 10px" }}>
        <p>
          <strong>СОГЛАСИЕ</strong>
        </p>
        <p>
          <strong>на обработку персональных данных</strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          Пользователь в соответствии с положениями Федерального закона от
          27.07.2006 № 152-ФЗ &laquo;О персональных данных&raquo;,{" "}
          <strong>в целях:</strong>
        </p>
        <p>
          - обеспечения соблюдения законов и иных нормативных правовых актов;
        </p>
        <p>
          - обеспечения для корректной работы и/или сбора статистики
          использования сервисов Сайта [rukki.pro] (в том числе с использованием
          cookie);
        </p>
        <p>
          - формирования информационной базы Пользователей для рассылки им
          информационных (рекламных) оповещений с применением неголосовых
          коммуникаций (путем рассылки по сети подвижной радиотелефонной связи
          коротких текстовых sms-сообщений, рассылки ussd- сообщений и др.)
          и/или с применением голосовых коммуникаций (телефонный звонок);
        </p>
        <p>
          - иных целях, указанных в Политике в отношении обработки персональных
          данных,
        </p>
        <p>
          <strong>
            дает согласие на обработку и передачу Персональных данных, способами
            и в объеме, предусмотренном Политикой в отношении обработки
            персональных данных.
          </strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          Перечень Персональных данных, на обработку которых дается согласие:
        </p>
        <ul>
          <li>фамилия, имя, отчество;</li>
          <li>
            контактные данные (включая, но не ограничиваясь: телефонный номер,
            адрес электронной почты, идентификаторы в социальных сетях и т.п.);
          </li>
          <li>иные персональные данные, предоставляемые Пользователем.</li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          Настоящее согласие действует со дня его выдачи, которое подтверждается
          фактом использования Пользователем сервисов Сайта (в соответствии с п.
          1.8. Политики в отношении обработки персональных данных), в том числе
          путем заполнения Пользователем формы обратной связи на Сайте и
          действует до дня отзыва в письменной форме.
        </p>
        <p>
          Пользователь подтверждает, что, давая согласие, действует по
          собственной воле и в своих интересах.
        </p>
        <p>
          Все термины и определения, употребляемые в настоящем согласии, имеют
          то же значение, что и в Политике в отношении обработки персональных
          данных, опубликованной (размещенной) на Сайте Общества.
        </p>
      </div>
    </PopUpContainer>
  );
};

export default UserAgreementPopUp;
