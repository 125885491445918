export const getPhotoStatus = (status: string | undefined): string => {
  switch (status) {
    case "confirmed":
      return "Проверка пройдена";
    case "not-confirmed":
      return "Проверка не пройдена";
    case "pending":
      return "Данные проверяются";
    default:
      return "Нет загруженных фотографий";
  }
};

export const getFullPhotoStatus = (status: string | undefined): string => {
  switch (status) {
    case "confirmed":
      return "Проверка пройдена";
    case "not-confirmed":
      return "Проверка не пройдена";
    case "pending":
      return "Данные проверяются";
    default:
      return "Нет загруженных фотографий";
  }
};

export const getColorPhotoStatus = (status: string | null): string => {
  switch (status) {
    case "confirmed":
      return "fulfilled";
    case "not-confirmed":
      return "rejected";
    case "pending":
      return "pending";
    default:
      return "empty";
  }
};

export const getPaymentStatus = (status: string): string => {
  switch (status) {
    case "Initiated":
      return "Все необходимые реквизиты для платежа получены, платёж готов к проверке на возможность проведения";
    case "Wait For Owner Requisites":
      return "Часть реквизитов для платежа получена, кроме реквизитов плательщика";
    case "NotAllowed":
      return "Платёж нельзя провести: либо у пользователя нет прав для подписи, либо платёж заблокирован комплаенсом";
    case "Allowed":
      return "Платёж готов к подписанию, все проверки пройдены";
    case "WaitingForSign":
      return "Платёж ждёт подписи";
    case "WaitingForCreate":
      return "Платёж подписан, ждёт создания внутри систем банка";
    case "Created":
      return "Платёж создан";
    case "Rejected":
      return "Платёж отменён";
    default:
      return "empty";
  }
};

export const getToken = () => {
  return localStorage.getItem("accessToken");
};

export const getHistoryName = (type: string): string => {
  switch (type) {
    case "Sent":
      return "Заявка подана";
    case "Accepted":
      return "Принята";
    case "Rejected":
      return "Отказано";
    case "created":
      return "Заказ создан";
    case "waiting_for_start":
      return "Ожидание начала \nработ"; // работ";
    case "edited":
      return "Заказ создан";
    case "in":
      return "Заказ создан";
    case "worker_selected":
      return "Подрядчик выбран";
    case "started":
      return "Начало работ";
    case "done":
      return "Завершение";
    case "paid":
      return "Оплачено";
    case "waiting_for_finish":
      return "Ожидание окончания \nработ"; // работ";
    default:
      return "Неизвестный тип";
  }
};
export const getHistoryNameBlock = (type: string): string => {
  switch (type) {
    case "Sent":
      return "Заявка подана";
    case "Accepted":
      return "Принята";
    case "Rejected":
      return "Отказано";
    case "created":
      return "Заказ создан";
    case "waiting_for_start":
      return "Ожидание начала работ";
    case "waiting_for_finish":
      return "Ожидание окончания работ";
    case "edited":
      return "Заказ создан";
    case "in":
      return "Заказ создан";
    case "worker_selected":
      return "Подрядчик выбран";
    case "started":
      return "Начало работ";
    case "done":
      return "Завершение";
    case "paid":
      return "Оплачено";
    default:
      return "Неизвестный тип";
  }
};

export const getWorkerData = (array: any): any | null => {
  const result = array.find((item: any) => item.status === "Accepted");
  if (result) return result;
  return null;
};
