import React, { useState, useEffect, useCallback } from "react";
import { Col, Row } from "antd";
import { useParams } from "react-router-dom";
import { Button, Container } from "./styles";
import UserInfoLeftSide from "./UserInfoLeftSide";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { RootState } from "../../../redux/store/store";
import {
  getParrentUserById,
  getRefUserById,
} from "../../../redux/store/reducers/referalsSlice";
import UserInfoTable from "./UserInfoTable";
import axios from "axios";
import { ENDPOINT } from "../../../constants/general copy";
import { getToken } from "../../../utils/uiHelpers";

const UserInfo: React.FC = () => {
  const params = useParams();
  const [info, setInfo] = useState<any[]>([]);
  const [refList, setRefList] = useState<"agent" | "worker" | "">("");
  const dispatch = useAppDispatch();
  const [currentUser, setCurrentUser] = useState<any>();

  const parentId = useAppSelector(state => state.referals.currentUser?.customer?.parent)
  const parent = useAppSelector(state => state.referals.parrentUser);

  const { loading, parrentUser } = useAppSelector(
    (state: RootState) => state.referals
  );
  const { user } = useAppSelector((state: RootState) => state.auth);

  const fetchUser = useCallback(
    async () => {
      try {
        console.log(params?.id);
        const { data } = await axios.get(`${ENDPOINT}/api/analytics/${user?.customer?.refId}/users/${params?.id ? params?.id : user?.customer?.id}`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });
        setCurrentUser(data);
      } catch (error) {
        
      }
    },
    [user?.customer, params?.id],
  )

  useEffect(() => {
    fetchUser(); 
  }, [fetchUser])

  useEffect(() => {
    currentUser && dispatch(getParrentUserById(currentUser.customer.parent))
  }, [currentUser])

  // useEffect(() => {
  //   dispatch(getParrentUserById(parentId))
  // }, [dispatch])

  useEffect(() => {
    console.log("params", params.id);
    if (params && params.id) dispatch(getRefUserById(parseInt(params?.id ? params?.id : user?.customer?.id)));
    //dispatch(getParrentUserById(parentId))
    return () => {
      onClickShowRefList("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const onClickShowRefList = (name: any) => {
    console.log("name", name);
    setRefList(name);
  };

  if (loading === "pending") return <div>Загрузка...</div>;

  console.log("currentUser?.registrations", currentUser?.registrations);

  return (
    <Container>
      <Row>
        <Col span={24}>
          <UserInfoLeftSide
            parentUser={parrentUser}
            refList={refList}
            info={info}
            onClickShowRefList={onClickShowRefList}
            registrations={currentUser?.registrations || []}
            role={currentUser?.customer?.role || "Роль не указана"}
            transactionsCount={currentUser?.reward?.transactionsCount || 0}
            currentUser={currentUser}
          />
        </Col>
        {/* <UserInfoRightSide
          onClickShowRefList={onClickShowRefList}
          refListData={currentUser?.registrations || []}
          role={refList}
        /> */}
      </Row>
      <Row>
        <Col span={24}>
          <UserInfoTable
            onClickShowRefList={onClickShowRefList}
            refListData={currentUser?.registrations || []}
            role={refList}
          />
        </Col>
      </Row>
      <Row>
        <Button>Загрузить</Button>
      </Row>
    </Container>
  );
};

export default UserInfo;
