import styled from "styled-components";
import { Table as AndTable } from 'antd';

export const Table = styled(AndTable)`
&& thead > tr > th {
    font-weight: 500;
    background: #fff;
  }
  && tbody > tr > td {
    cursor: pointer;
    font-weight: 500;
  }
  && tbody > tr:hover > td {
    background: #FF6633;
    color: #fff;
  }
  && tbody > tr:hover a {
    color: #fff;
  }
`;


