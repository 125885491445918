import styled from "styled-components";
import { Container } from "../styles/styles";

export const FooterWrapper = styled.footer`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width:576px) {
    display: none;
  }
`;

export const FooterContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 720px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

export const Logo = styled.div`
  height: 40px;
  img {
    height: 100%;
  }

  @media only screen and (max-width: 1050px) {
    height: 30px;
  }
`;

export const Navs = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 720px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

export const NavsWrapper = styled.div`
  display: flex;
  div {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: #757575;
    text-decoration: none;
    margin-left: 47px;
    cursor: pointer;
  }
  @media only screen and (max-width: 720px) {
    margin: 20px 0;
    div{
        margin: 0 20px;
    }
  }
`;

export const SocialMediaWrapper = styled.div`
  width: 144px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 58px;
  @media only screen and (max-width: 720px) {
    margin: 0;
  }
`;

export const SocialMediaLogo = styled.a`
  width: 32px;
  height: 32px;
`;
