import React, {memo, useEffect, useState} from "react";
import {ContentTitle} from "../../components/styles/styles";
import {HintBox, HintContent, VehicleBox, VehicleItem, VehicleList, VehicleWrapper} from "./styles";
import {ReactComponent as ArrowRight} from "../../assets/icons/arrow-right.svg";
import {ReactComponent as QuestionMarkIcon} from "../../assets/icons/question-mark.svg";
import {getVehicleCategories, getVehiclesList,} from "../../redux/store/reducers/vehicleSlice";
import MGoBack from "../../components/styles";
import {Button} from "../../components/form/styles";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store/store";
// import {HintBox, HistContent} from "../InviteFriendsPage/styles";
import {Popover} from "antd";
import EditVehiclePage from "../editVehiclePage";

const getCategoryName = (categories: any, id: number): string => {
  const result = categories.find((item: any) => item.id === id);
  return result?.title || "Нет категории";
};

const VehiclePage: React.FC = () => {
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [path,setPath]=useState("list");

  const vehicleCategories = useAppSelector(
    (state: RootState) => state.vehicles.vehicleCategories
  );
  const vehicleList = useAppSelector(
    (state: RootState) => state.vehicles.vehicleList
  );

  const { user } = useAppSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (user && user.customer.refId) {
      dispatch(getVehiclesList(user.customer.refId));
      dispatch(getVehicleCategories());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>{path==="list"?
    <VehicleWrapper>
      <ContentTitle>
        {/* <MGoBack /> */}
        <Button fit onClick={() => setPath("add")}>
          Добавить технику
        </Button>
      </ContentTitle>
      <VehicleBox>
        <VehicleList>
          {vehicleList.map((vehicle: any, index: number) => (
            <VehicleItem
              key={`vehicle${index}`}
              // to={`/my-vehicle/${vehicle.id}`}
              onClick={()=>setPath(vehicle.id)}
            >
              <div className="number">{index + 1}</div>
              <div className="data">
                <div className="view">
                  {getCategoryName(vehicleCategories, vehicle.categoryId)}
                </div>
                <div className="name">{vehicle.brand}</div>
              </div>
              <div className="number">{vehicle.stateNumber}</div>
              <div className="arrow-right">
                <ArrowRight />
              </div>
            </VehicleItem>
          ))}
        </VehicleList>
      </VehicleBox>
      {/* <VehicleBox> */}
      {/* <div className="title">Дорожная техника</div> */}
      {/* <VehicleItem to="/my-vehicle/3">
          <div className="name">Коммаш 65371</div>
          <div className="arrow-right">
            <ArrowRight />
          </div>
        </VehicleItem>
        <VehicleItem to="/my-vehicle/4">
          <div className="name">ДС-138Б на базе КАМАЗ-65115</div>
          <div className="arrow-right">
            <ArrowRight />
          </div>
        </VehicleItem> */}
      {/* </VehicleBox> */}

      <HintBox>
        <Popover
          style={{ clear: "both", maxWidth: "360px", width: "100%" }}
          content={
            <HintContent>
              Хотите получать заказы? <br />
              Добавьте спецтехнику и фотографии, пройдите верификацию и
              получайте заказы. Данные о технике помогут подобрать для вас
              наиболее выгодные заказы рядом с вами
            </HintContent>
          }
          trigger="click"
          className="popover"
          placement="rightTop"
        >
          <QuestionMarkIcon />
        </Popover>
      </HintBox>
    </VehicleWrapper>:
<EditVehiclePage path={path} setPath={setPath}/>
    }
    </>
  );
};

export default memo(VehiclePage);
