import React, { useEffect } from "react";
import { ContentTitle } from "../../components/styles/styles";
import { ProfileWrapper, StyledRow } from "./styles";
import MGoBack from "../../components/styles";
import "moment/locale/ru";
import UsersTable from "./UsersTable";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { RootState } from "../../redux/store/store";
import { getReferals } from "../../redux/store/reducers/referalsSlice";
import { Row, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { Button } from "./UserInfo/styles";

const Users: React.FC = () => {
  const { entities } = useAppSelector((state: RootState) => state.referals);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getReferals({ current: 1, pageSize: 10, navigate }));
  }, [dispatch]);

  const changePageHandler = (current: number, pageSize: number) => {
    dispatch(getReferals({ current, pageSize, navigate }));
  };

  return (
    <ProfileWrapper>
      <ContentTitle>
        <MGoBack />
        <StyledRow>
        <Typography.Title level={2}>
          {" "}
          Пользователи (Связь с СРМ)
        </Typography.Title>
        <Button>Загрузить</Button>
        </StyledRow>
        
        <UsersTable
          usersData={entities?.users}
          current={entities?.current}
          total={entities?.total}
          // columns={columns}
          changePageHandler={changePageHandler}
        />
        
      </ContentTitle>
    </ProfileWrapper>
  );
};

export default Users;
