import styled from "styled-components";
import { styles } from "../styles/styles";

export const SidebarContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  @media only screen and (max-width: 576px) {
    min-height: 100vh;
  }
`;

export const SidebarPart = styled.div`
  width: 390px;
  margin-right: 32px;
  height: fit-content;
  background-color: ${({ raw }: { raw?: boolean; mobile?: boolean }) =>
    raw ? "transparent" : "white"};
  padding: ${({ raw }: { raw?: boolean; mobile?: boolean }) =>
    raw ? "" : "10px 10px 25px"};
  overflow: auto;
  border-radius: ${({ raw }: { raw?: boolean; mobile?: boolean }) =>
    raw ? "0px" : "8px"};
    display: ${({ mobile }: { mobile?: boolean }) =>
      mobile ? "block" : "none"};
  @media only screen and (max-width: 1250px) {
    width: 300px !important;
    display: ${({ mobile }: { mobile?: boolean }) =>
      mobile ? "block" : "none"};
  }
  @media only screen and (max-width: 1050px) {
    margin-right: 15px;
    display: ${({ mobile }: { mobile?: boolean }) =>
      mobile ? "none" : "block"};
  }
  @media only screen and (max-width: 720px) {
    width: 100% !important;
    margin: 0 0 20px;
    display: ${({ mobile }: { mobile?: boolean }) =>
      mobile ? "block" : "none"};
  }
  @media only screen and (max-width: 576px) {
    width: 100% !important;
    height: 100vh;
    margin: 0;
    display: ${({ mobile }: { mobile?: boolean }) =>
      mobile ? "block" : "none"};
    padding: 20px 20px 25px;
  }
`;

export const ContentPart = styled.div`
  /* width: calc(100% - 422px); */
  width: ${({ mobile }: { mobile?: boolean }) =>
      mobile ? "calc(100% - 422px)" : "100%"};
  min-height: 100%;
  background-color: ${({ raw }: { raw?: boolean; mobile?: boolean }) =>
    raw ? "transparent" : "white"};
  border-radius: 8px;
  padding: ${({ raw }: { raw?: boolean; mobile?: boolean }) =>
    raw ? "0px" : "32px 32px 40px"};
  @media only screen and (max-width: 1250px) {
    /* width: calc(100% - 332px); */
    width: ${({ mobile }: { mobile?: boolean }) =>
      mobile ? "calc(100% - 332px)" : "100%"};
  }
  @media only screen and (max-width: 1050px) {
    width: 100%;
    display: ${({ mobile }: { mobile?: boolean }) =>
      mobile ? "block" : "none"};
    padding: ${({ raw }: { raw?: boolean; mobile?: boolean }) =>
      raw ? "0" : "16px 16px 20px"};
  }
  @media only screen and (max-width: 720px) {
    width: 100%;
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
    padding: 40px 16px;
    display: ${({ mobile }: { mobile?: boolean }) =>
      mobile ? "none" : "block"};
  }
`;

export const HelpBtn = styled.div`
  background-color: ${styles.mainColor};
  color: white;
  width: fit-content;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding: 15px;
  border-radius: 10px;
  position: fixed;
  bottom: ${({ bottom }: { bottom: number }) =>
    bottom ? bottom + "px" : "30px"};
  right: 30px;
  cursor: pointer;
  span {
    margin: 0 15px;
  }
  @media only screen and (max-width: 850px) {
    padding: 10px;
    /* bottom: 20px; */
    bottom: ${({ bottom }: { bottom: number }) =>
      bottom ? bottom + "px" : "20px"};
    right: 20px;
    span {
      display: none;
    }
  }
`;
