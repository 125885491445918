import styled from "styled-components";
import { styles } from "../styles/styles";

export const ErrorMsg = styled.div`
  color: ${styles.red};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #ff3358;

  mix-blend-mode: normal;
  opacity: 0.5;
  margin-bottom: 7px;
`;

export const PinInput = styled.div`
  display: "flex" !important;
`;

export const PinInputField = styled.input`
  width: 44px;
  height: 44px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  border-radius: 10px;

  border: 1px solid
    ${({ error }: { error: boolean }) =>
      error ? styles.red : "rgba(147, 147, 147, 1)"};
  outline: 0;
  ${({ error }: { error: boolean }) => (error ? `color:${styles.red};` : "")}

  &:focus {
    border-color: ${({ error }: { error: boolean }) =>
      error ? styles.red : "rgba(254, 200, 38, 1)"};
  }

  /* &:valid{
    background-color: red;
  } */

  &:last-child{
    margin-right: 0;
  }

  @media only screen and (max-width: 350px){
    width: 35px;
    height: 35px;
    margin-right: 3px;
  }

`;

export const CodeSendTo = styled.p`
  margin-top: 18px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #222222;

  mix-blend-mode: normal;
  opacity: 0.5;
`;

export const DidNotReceiveCode = styled.p`
  margin: 28px 0 4px;
  color: #222222;

  mix-blend-mode: normal;
  opacity: 0.5;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

export const TimeRemained = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  span {
    color: #222222;

    mix-blend-mode: normal;
    opacity: 0.5;
  }
`;
