import React from "react";
import { Body, PageWrapper } from "./styles";

interface Props {
  title?: string;
  children: string | React.ReactNode;
  goBack?: (() => void) | null;
  agentMode?: any;
}
const PageContainer: React.FC<Props> = ({
  title,
  children,
  goBack,
  agentMode,
}) => {
  return (
    <PageWrapper>
      {/* {title && (
        <TitlePart>
          {goBack && (
            <GoBack onClick={goBack}>
              <LeftArrow /> Назад
            </GoBack>
          )}
          <Title>
            <MenuOutlined
              style={{ marginRight: "20px", cursor: "pointer" }}
              onClick={sidebarToggler}
            />
            {title}
          </Title>
        </TitlePart>
      )} */}
      <Body>{children}</Body>
    </PageWrapper>
  );
};

export default PageContainer;
