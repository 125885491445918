import React from "react";
import { MobileGoBack } from "./styles";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { useNavigate } from "react-router-dom";
const MGoBack: React.FC<any> = (props) => {
    const navigate = useNavigate();
  return (
    <MobileGoBack onClick={()=>navigate("/")} {...props}>
      <ArrowLeft />
    </MobileGoBack>
  );
};

export default MGoBack;
