import { Select } from "antd";
import styled from "styled-components";

export const EditVehicleWrapper = styled.div`
  padding: 10px;
`;

export const FormWrapper = styled.div`
  width: 500px;
  padding-top: 28px;

  button {
    margin-top: 25px;
  }

  @media only screen and (max-width: 1050px) {
    width: 100%;
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
    padding-bottom: 10px;
    button {
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      border-radius: 0;
    }
  }
`;

export const SelectCustom = styled(Select)`
  margin-bottom: 0;
  width: 100%;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const FormGroup = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
`;

export const SelectLabelText = styled.div`
  color: #2222227f;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 2px;
`;

export const DocumentsWrapper = styled.div`
  width: 100%;
`;

export const DocTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 13px !important;
  margin-top: 20px;
`;

export const DocsListWrapper = styled.div`
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.24);
  border-radius: 10px;

  div {
    margin-bottom: 0 !important;
  }
`;

export const DocItem = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon {
    margin-right: 32px;
    display: flex;
  }

  .data {
    margin-right: 20px;

    .title {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.87);
    }

    .subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.543846);

      .pending {
        color: #fec826;
      }

      .fulfilled {
        color: #71c562;
      }

      .rejected {
        color: #ff3358;
      }
    }
  }

  .arrow-right {
    margin-left: auto;
    display: flex;
  }

  &:last-child {
    border-bottom: 0;
  }
`;
