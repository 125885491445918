import styled from "styled-components";

export const StatusBox = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  .status-part {
    margin-right: 12px;
    width: 24px;
  }
  .text-part {
    width: calc(100% - 36px);
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  
  }
  .text-part {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
`;