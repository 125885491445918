import React from "react";
import { Navigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { authentication } from "../../firebase/config";
import { useAppDispatch } from "../../hooks";
import { clearAuth, 
  // setAuth
 } from "../../redux/store/reducers/authSlice";

type Props = {
  path?: string;
  children: any;
};
const token = () => localStorage.getItem("accessToken");

const PrivateRoute = ({ children }: Props) => {
  const dispatch = useAppDispatch();

  if (!token()) {
    signOut(authentication).then(() => {
      localStorage.removeItem("accessToken");
      dispatch(clearAuth());
    });
    return <Navigate to="/login" replace={true} />;
  } 
  // else dispatch(setAuth());

  return <React.Fragment>{children}</React.Fragment>;
};

export default PrivateRoute;
