import React, { useState, useEffect } from "react";
import PopUpContainer from "../popUpContainer";
import { PhotoVideoBox, PhotoVideoItem, PhotoVideoList } from "./styles";

import { ReactComponent as Close } from "../../../assets/icons/close-red.svg";
import { ReactComponent as Camera } from "../../../assets/icons/camera.svg";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import {
  getVehicleImages,
  getPassportImages,
} from "../../../redux/store/reducers/vehicleSlice";
import { RootState } from "../../../redux/store/store";
import { getColorPhotoStatus } from "../../../utils/uiHelpers";
import RejectedStatus from "../../RejectedStatus";
import { BASE_URL } from "../../../constants/general";

interface Props {
  onSubmit?: any;
  close?: any;
  customerId: string;
  vehicleId: string;
  removePhoto: (customerId: string, vehicleId: string) => void;
  stateName: "vehicleImages" | "passportImages";
  rejectReason: string;
  documentStatus: string;
}

const uploadCondition = (imgArray: any) => {
  if (imgArray === null) return true;
  if (imgArray && imgArray.length === 0) return true;
  if (imgArray && imgArray.length > 0) return false;
  return false;
};

const uploadNotification = (stateName: string, count: number): string => {
  if (stateName === "vehicleImages" && count > 5)
    return "Можно добавить не более 5 фотографий";
  if (stateName === "passportImages" && count > 1)
    return "Можно добавить не более 1 фотографии";
  return "";
};

const VehiclePhotoPopUp: React.FC<Props> = ({
  close,
  onSubmit,
  removePhoto,
  customerId,
  vehicleId,
  stateName,
  rejectReason,
  documentStatus,
}) => {
  const [photoVideo, setPhotoVideo] = useState<any>([]);
  const vehicleImg = useAppSelector(
    (state: RootState) => state.vehicles.vehicleImages as any
  );

  const loadingImage = useAppSelector(
    (state: RootState) => state.vehicles.loadingImages as any
  );

  const loadingPassport = useAppSelector(
    (state: RootState) => state.vehicles.loadingPassport as any
  );

  const getStatus = getColorPhotoStatus(documentStatus);

  const passportImg = useAppSelector(
    (state: RootState) => state.vehicles.passportImages
  );

  const loading = useAppSelector((state: RootState) => state.vehicles.loading);

  const [photoCountError, setPhotoCountError] = useState<string>("");
  const myDispatch = useAppDispatch();

  let vehicleImages: any = null;
  if (stateName === "vehicleImages") vehicleImages = vehicleImg;

  if (stateName === "passportImages") vehicleImages = passportImg;

  // "pending"
  // "fulfilled"
  // "rejected"
  // "empty"

  useEffect(() => {
    if (stateName === "vehicleImages" && photoVideo.length <= 5)
      setPhotoCountError("");
    if (stateName === "passportImages" && photoVideo.length <= 1)
      setPhotoCountError("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoVideo]);

  useEffect(() => {
    if (stateName === "vehicleImages")
      myDispatch(getVehicleImages({ customerId, vehicleId }));
    else if (stateName === "passportImages")
      myDispatch(getPassportImages({ customerId, vehicleId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e: any) => {
    const files = e.target.files;
    const filesCount = files.length;
    const addedCount = photoVideo.length;
    const getImageCountError = uploadNotification(
      stateName,
      filesCount + addedCount
    );

    if (getImageCountError !== "") setPhotoCountError(getImageCountError);
    else {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const form = new FileReader();
        form.readAsDataURL(file);
        form.onload = () => {
          setPhotoVideo((prevState: any) => [
            ...prevState,
            {
              file,
              base64: form.result,
            },
          ]);
        };
      }
    }
  };

  return (
    <PopUpContainer
      close={close}
      onSubmit={() => {
        if (uploadCondition(vehicleImages)) onSubmit(photoVideo);
        else removePhoto(customerId, vehicleId);
      }}
      title={
        stateName === "vehicleImages" ? "Фотография техники" : "Фотография СТС"
      }
      button={
        uploadCondition(vehicleImages) ? "Добавить файлы" : "Удалить файлы"
      }
      disabled={
        (photoVideo.length === 0 && loading === "pending") ||
        loadingImage === "pending" ||
        loadingPassport === "pending"
      }
    >
      <PhotoVideoBox>
        {/* <Title>Допуск к работам</Title> */}
        {/* <Desc>
          Для работы с сервисом и подтверждением профиля необходимо загрузить
          скан паспорта: первый разворот и актуальная прописка. <br />
          После загрузки документ будет проверен службой поддержки.
        </Desc> */}
        {/* <div className="text">Загрузите или перетащите файлы</div> */}

        {/* <PhotoVideoList full={!uploadCondition(vehicleImages)}> */}

        {photoCountError !== "" && (
          <div
            style={{
              width: "100%",
              color: "red",
              padding: "5px",
            }}
          >
            {photoCountError}
          </div>
        )}
        <PhotoVideoList
          full={
            photoVideo.length === 0 &&
            vehicleImages &&
            vehicleImages.length === 0
          }
        >
          {uploadCondition(vehicleImages) && (
            <PhotoVideoItem
              dashed
              full={
                photoVideo.length === 0 &&
                vehicleImages &&
                vehicleImages.length === 0
              }
            >
              <div className="item">
                <label className="new-img">
                  <Camera />
                  <div className="new-img-text">
                    {photoVideo.length === 0 ? (
                      <>
                        Перетащите файлы в эту область <br /> или нажмите для
                        поиска на диске
                      </>
                    ) : (
                      "Добавить"
                    )}
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={onChange}
                  />
                </label>
              </div>
            </PhotoVideoItem>
          )}
          {!uploadCondition(vehicleImages) &&
            loading !== "pending" &&
            vehicleImages.map((imageItem: any, i: number) => {
              return (
                <PhotoVideoItem key={i} full={vehicleImages.length === 0}>
                  <div className="item">
                    <img
                      src={`${BASE_URL}/customers/image/${imageItem.photo}`}
                      alt=""
                    />
                  </div>
                </PhotoVideoItem>
              );
            })}

          {photoVideo
            .filter((v: any, index: number) =>
              photoVideo.length <= 7
                ? true
                : photoVideo.length > 7 && index < 6
                ? true
                : false
            )
            .map((f: any, i: number) => (
              <PhotoVideoItem key={i}>
                <div className="item">
                  <img src={f.base64} alt="" />
                </div>
                <div
                  className="close"
                  onClick={() =>
                    setPhotoVideo(
                      photoVideo.filter((v: any, index: number) => index !== i)
                    )
                  }
                >
                  <Close />
                </div>
              </PhotoVideoItem>
            ))}
          {photoVideo.length > 7 && (
            <PhotoVideoItem>
              <div className="item">
                <div className="cover">+{photoVideo.length - 6}</div>
                <img src={photoVideo[6].base64} alt="" />
              </div>
            </PhotoVideoItem>
          )}
        </PhotoVideoList>
        <RejectedStatus status={getStatus} reason={rejectReason} />
      </PhotoVideoBox>
    </PopUpContainer>
  );
};

export default VehiclePhotoPopUp;
