import React from 'react'
import PopUpContainer from '../popUpContainer'

const ConfidentialityPopUp:React.FC<{close:any}> = ({close}) => {
  return (
    <PopUpContainer
          big
          close={close}
        >
          <div style={{ padding: "0 10px" }}>
            <p>
              <strong>ПОЛИТИКА&nbsp;</strong>
            </p>
            <p>
              <strong>В ОТНОШЕНИИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
            </p>
            <p>
              <br />
            </p>
            <ol>
              <li>
                <strong>ОБЩИЕ ПОЛОЖЕНИЯ</strong>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol>
              <ol>
                <li>
                  Настоящий документ определяет политику (далее &ndash;
                  &laquo;Политика&raquo;) Общества с ограниченной
                  ответственностью &laquo;РУККИ&raquo; ОГРН 1227700503964 (далее
                  &ndash; &laquo;Общество&raquo;) в отношении обработки
                  персональных данных.
                </li>
                <li>
                  Настоящая Политика разработана и утверждена Обществом в
                  соответствии с требованиями статьи 18.1 Федерального закона от
                  27.07.2006 г. №152-ФЗ &laquo;О персональных данных&raquo;
                  (далее &ndash; &laquo;Закон&raquo;) и действует в отношении
                  всех персональных данных, обрабатываемых в Обществе.&nbsp;
                </li>
                <li>
                  Целью настоящей Политики является обеспечение защиты прав и
                  свобод человека и гражданина при обработке его персональных
                  данных, а также интересов Общества.&nbsp;
                </li>
                <li>
                  Настоящая Политика определяет цели, принципы и условия
                  обработки персональных данных сотрудников и иных лиц, чьи
                  персональные данные обрабатываются Обществом, а также включает
                  перечень мер, применяемых в целях обеспечения безопасности
                  персональных данных при их обработке.
                </li>
                <li>
                  Политика распространяется на отношения в области обработки
                  персональных данных, возникшие у Общества как до, так и после
                  утверждения настоящей Политики.
                </li>
                <li>
                  Во исполнение требований ч. 2 ст. 18.1 Закона настоящая
                  Политика публикуется в свободном доступе в
                  информационно-телекоммуникационной сети Интернет на Сайте
                </li>
                <li>
                  Правовым основанием обработки персональных данных является
                  совокупность нормативных правовых актов, во исполнение которых
                  и в соответствии с которыми Общество осуществляет обработку
                  персональных данных.
                </li>
                <li>
                  Использование сервисов Сайта означает безоговорочное согласие
                  Пользователя с настоящей Политикой и согласие Пользователя на
                  обработку любой информации, в том числе необходимой для
                  корректной работы и/или сбора статистики использования
                  сервисов Сайта (в том числе cookie) или предоставляемой
                  Пользователем самостоятельно, включая Персональные данные, а
                  также согласие на получение им информационных (рекламных)
                  оповещений. Указанные оповещения могут быть предоставлены с
                  применением неголосовых коммуникаций (путем рассылки по сети
                  подвижной радиотелефонной связи коротких текстовых
                  sms-сообщений, рассылки ussd-сообщений и др.), посредством
                  направления сведений по информационно-телекоммуникационной
                  сети &laquo;Интернет&raquo; на предоставленный Пользователем
                  номер телефона и (или) адрес электронной почты.&nbsp;
                </li>
              </ol>
            </ol>
            <p>
              Пользователь подтверждает, что, давая такое согласие, действует по
              собственной воле и в своих интересах.
            </p>
            <p>
              <br />
            </p>
            <ol>
              <li>
                <strong>
                  ОСНОВНЫЕ ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ, ИСПОЛЬЗУЕМЫЕ В ПОЛИТИКЕ
                </strong>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol>
              <ol>
                <li>
                  <strong>
                    Автоматизированная обработка персональных данных
                  </strong>{" "}
                  - обработка персональных данных с помощью средств
                  вычислительной техники.
                </li>
                <li>
                  <strong>Биометрические персональные данные</strong> -
                  сведения, которые характеризуют физиологические и
                  биологические особенности человека, на основании которых можно
                  установить его личность и которые используются оператором для
                  установления личности субъекта персональных данных.
                </li>
                <li>
                  <strong>Блокирование персональных данных</strong> - временное
                  прекращение обработки персональных данных (за исключением
                  случаев, если обработка необходима для уточнения персональных
                  данных).
                </li>
                <li>
                  <strong>Безопасность персональных данных</strong> - состояние
                  защищенности персональных данных, характеризуемое способностью
                  Пользователей, технических средств и информационных технологий
                  обеспечить конфиденциальность, целостность и доступность
                  персональных данных при их обработке в информационных системах
                  персональных данных.
                </li>
                <li>
                  <strong>Информационная система персональных данных</strong> -
                  совокупность баз персональных данных, информационных
                  технологий и технических средств, позволяющих осуществлять
                  обработку таких персональных данных с использованием средств
                  автоматизации или без таковых.
                </li>
                <li>
                  <strong>Конфиденциальность персональных данных</strong> -
                  обязательное для соблюдения Обществом или иным получившим
                  доступ к персональным данным лицом требование не допускать их
                  распространение без согласия Пользователя или наличия иного
                  законного основания.&nbsp;
                </li>
                <li>
                  <strong>Обработка персональных данных -&nbsp;</strong>любое
                  действие (операция) или совокупность действий (операций) с
                  персональными данными, совершаемых с использованием средств
                  автоматизации или без их использования. Обработка персональных
                  данных включает в себя в том числе:
                </li>
              </ol>
            </ol>
            <ul>
              <li>сбор;</li>
              <li>запись;</li>
              <li>систематизацию;</li>
              <li>накопление;</li>
              <li>хранение;</li>
              <li>уточнение (обновление, изменение);</li>
              <li>извлечение;</li>
              <li>использование;</li>
              <li>передачу (распространение, предоставление, доступ);</li>
              <li>обезличивание;</li>
              <li>блокирование;</li>
              <li>удаление;</li>
              <li>уничтожение;</li>
            </ul>
            <ol>
              <ol>
                <li>
                  <strong>Общедоступные персональные данные</strong> -
                  персональные данные, доступ неограниченного круга лиц к
                  которым предоставлен с согласия Пользователя или на которые в
                  соответствии с федеральными законами не распространяется
                  требование соблюдения конфиденциальности.
                </li>
                <li>
                  <strong>Обезличивание персональных данных</strong> - действия,
                  в результате которых становится невозможным без использования
                  дополнительной информации определить принадлежность
                  Персональных данных конкретному Пользователю.&nbsp;
                </li>
                <li>
                  <strong>Предоставление персональных данных</strong> -
                  действия, направленные на раскрытие персональных данных
                  определенному лицу или определенному кругу лиц.&nbsp;
                </li>
                <li>
                  <strong>Персональные данные</strong> - любая информация,
                  относящаяся к прямо или косвенно определенному, или
                  определяемому физическому лицу (субъекту персональных данных -
                  Пользователю).
                </li>
                <li>
                  <strong>Пользователь&nbsp;</strong>&ndash; лицо, пользующее
                  и/или намеревающееся использовать Сайт, являющееся субъектом
                  Персональных данных;
                </li>
                <li>
                  <strong>Распространение персональных данных</strong> -
                  действия, направленные на раскрытие персональных данных
                  неопределенному кругу лиц.
                </li>
                <li>
                  <strong>Сайт&nbsp;</strong>- совокупность программ для
                  электронных вычислительных машин и иной информации, доступ к
                  которой обеспечивается посредством
                  информационно-телекоммуникационной сети &laquo;Интернет&raquo;
                  [asterus-development.com]
                </li>
                <li>
                  <strong>Трансграничная передача персональных данных</strong> -
                  передача персональных данных на территорию иностранного
                  государства органу власти иностранного государства,
                  иностранному физическому лицу или иностранному юридическому
                  лицу.&nbsp;
                </li>
                <li>
                  <strong>Уничтожение персональных данных</strong> - действия, в
                  результате которых становится невозможным восстановить
                  содержание персональных данных в информационной системе
                  персональных данных и/или в результате которых уничтожаются
                  материальные носители персональных данных.&nbsp;
                </li>
              </ol>
            </ol>
            <p>
              <br />
            </p>
            <ol>
              <li>
                <strong>ЦЕЛИ СБОРА И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol>
              <ol>
                <li>
                  Обработка персональных данных ограничивается достижением
                  конкретных, заранее определенных и законных целей. Не
                  допускается обработка персональных данных, несовместимая с
                  целями сбора персональных данных.
                </li>
                <li>
                  Обработке подлежат только персональные данные, которые
                  отвечают целям их обработки.
                </li>
                <li>
                  Общество осуществляет обработку персональных данных в
                  целях:&nbsp;
                </li>
              </ol>
            </ol>
            <ul>
              <li>
                обеспечения соблюдения Конституции Российской Федерации,
                законодательных и иных нормативных правовых актов Российской
                Федерации, локальных нормативных актов Общества;&nbsp;
              </li>
              <li>
                заключения с Пользователем любых договоров и соглашений, и их
                дальнейшего исполнения;&nbsp;
              </li>
              <li>осуществления своей деятельности;&nbsp;</li>
              <li>
                предоставления информации об Обществе, его услугах, акциях и
                мероприятиях;&nbsp;
              </li>
              <li>коммуникации с Пользователем;&nbsp;</li>
              <li>
                направления Пользователю новостных и/или рекламных материалов;
              </li>
              <li>
                обеспечения функционирования, безопасности и улучшения качества
                Сайта;
              </li>
              <li>осуществления пропускного режима;</li>
              <li>
                а также других целей, достижение которых не запрещено
                федеральным законодательством, международными договорами
                Российской Федерации.&nbsp;
              </li>
            </ul>
            <ol>
              <ol>
                <li>
                  Обработка персональных данных Пользователей может
                  осуществляться исключительно в целях обеспечения соблюдения
                  законов и иных нормативных правовых актов.
                </li>
                <li>
                  Обработка Персональных данных Обществом осуществляется на
                  основе следующих принципов:&nbsp;
                </li>
              </ol>
            </ol>
            <ul>
              <li>
                законности целей и способов обработки Персональных данных;&nbsp;
              </li>
              <li>
                соответствия целей обработки Персональных данных целям, заранее
                определенным и заявленным при сборе Персональных данных;&nbsp;
              </li>
              <li>
                соответствия состава и объема обрабатываемых Персональных
                данных, а также способов обработки Персональных данных
                заявленным целям обработки;&nbsp;
              </li>
              <li>
                достоверности Персональных данных, их достаточности для целей
                обработки, недопустимости обработки Персональных данных,
                избыточных по отношению к целям, заявленным при сборе
                Персональных данных;&nbsp;
              </li>
              <li>
                недопустимости обработки Персональных данных, несовместимых с
                целями сбора Персональных данных;&nbsp;
              </li>
              <li>
                обеспечения хранения Персональных данных не дольше, чем этого
                требуют цели Обработки персональных данных, если срок хранения
                Персональных данных не установлен действующим законодательством
                или соглашением между Пользователем и Обществом;&nbsp;
              </li>
              <li>
                уничтожения или обезличивания Персональных данных по достижении
                целей обработки или в случае утраты необходимости в достижении
                этих целей, если иное не установлено действующим
                законодательством или соглашением между Пользователем и
                Обществом.
              </li>
            </ul>
            <p>
              <br />
            </p>
            <ol>
              <li>
                <strong>
                  ОБЪЕМ И КАТЕГОРИИ ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ
                </strong>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol>
              <ol>
                <li>
                  Обработка Персональных данных ограничивается достижением
                  конкретных, заранее определенных и законных целей. Не
                  допускается обработка Персональных данных, несовместимая с
                  целями сбора Персональных данных.
                </li>
                <li>
                  Общество может обрабатывать следующие Персональные данные
                  Пользователя:
                </li>
              </ol>
            </ol>
            <ul>
              <li>фамилия, имя, отчество;</li>
              <li>
                контактные данные (включая, но не ограничиваясь: телефонный
                номер, адрес электронной почты, идентификаторы в социальных
                сетях и т.п.);
              </li>
              <li>иные персональные данные, предоставляемые Пользователем.</li>
            </ul>
            <ol>
              <ol>
                <li>
                  Обществом не осуществляется обработка специальных категорий
                  персональных данных, касающихся расовой, национальной
                  принадлежности, политических взглядов, религиозных или
                  философских убеждений, состояния здоровья, интимной жизни, за
                  исключением случаев, предусмотренных законодательством РФ.
                </li>
              </ol>
            </ol>
            <p>
              <br />
            </p>
            <ol>
              <li>
                <strong>УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol>
              <ol>
                <li>
                  Обработка персональных данных осуществляется с соблюдением
                  принципов и правил, установленных Законом.
                </li>
                <li>
                  Общество осуществляет Обработку персональных данных, как с
                  использованием средств автоматизации, так и без использования
                  средств автоматизации (автоматизированная и/или
                  неавтоматизированная Обработка персональных данных).&nbsp;
                </li>
                <li>
                  Общество может включать Персональные данные Пользователя в
                  общедоступные источники Персональных данных, при этом Общество
                  берет письменное согласие Пользователя на Обработку
                  персональных данных.
                </li>
                <li>
                  Биометрические персональные данные в Обществе не
                  обрабатываются.&nbsp;
                </li>
                <li>
                  Общество не осуществляет Трансграничную передачу персональных
                  данных.&nbsp;
                </li>
                <li>
                  К обработке персональных данных допускаются работники
                  Общества, в должностные обязанности которых входит обработка
                  персональных данных.
                </li>
                <li>
                  Обработка персональных данных осуществляется путем:
                  <ol>
                    <li>
                      получения Персональных данных в устной и письменной форме
                      непосредственно от Пользователя;
                    </li>
                    <li>
                      получения Персональных данных из общедоступных источников;
                    </li>
                    <li>
                      внесения/предоставления Персональных данных Пользователем
                      по средствам сервисов Сайта и/или внесения/предоставления
                      Персональных данных Пользователем в журналы, реестры и
                      иные информационные системы Общества;
                    </li>
                    <li>
                      использования иных способов Обработки персональных данных.
                    </li>
                  </ol>
                </li>
                <li>
                  Не допускается раскрытие третьим лицам и распространение
                  Персональных данных без согласия Пользователя, если иное не
                  предусмотрено действующим законодательством
                </li>
                <li>
                  При отсутствии необходимости письменного согласия Пользователя
                  на обработку его Персональных данных согласие может быть дано
                  Пользователем или его представителем в любой позволяющей
                  получить факт его получения форме, в том числе согласие
                  считается данным в случае использования Пользователем сервисов
                  Сайта;
                </li>
                <li>
                  Общество вправе поручить обработку Персональных данных другому
                  лицу с согласия Пользователя, если иное не предусмотрено
                  действующим законодательством, на основании заключаемого с
                  этим лицом договора. При этом Общество в договоре обязывает
                  лицо, осуществляющее обработку Персональных данных по
                  поручению Общества, соблюдать принципы и правила обработки
                  персональных данных, предусмотренные Законом.&nbsp;
                </li>
                <li>
                  Предоставление доступа органам государственной власти (в том
                  числе, контролирующих, надзорных, правоохранительных и иных
                  органов) к Персональным данным, обрабатываемым Обществом
                  осуществляется в объёме и порядке, установленным
                  соответствующим законодательством.&nbsp;
                </li>
              </ol>
            </ol>
            <p>
              <br />
            </p>
            <ol>
              <li>
                <strong>
                  ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ (СУБЪЕКТА ПЕРСОНАЛЬНЫХ
                  ДАННЫХ)
                </strong>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol>
              <ol>
                <li>
                  Пользователь имеет право:
                  <ol>
                    <li>
                      получать информацию, касающуюся обработки его Персональных
                      данных, в порядке, форме и сроки, установленные
                      законодательством;&nbsp;
                    </li>
                    <li>
                      требовать уточнения своих Персональных данных, их
                      блокирования и уничтожения в случае, если Персональные
                      данные являются неполными, устаревшими, неточными,
                      незаконно полученными или не являются необходимыми для
                      заявленной цели обработки;
                    </li>
                    <li>
                      принимать предусмотренные законом меры по защите своих
                      прав;&nbsp;
                    </li>
                    <li>
                      отозвать свое Согласие на обработку персональных данных,
                      направив письменное уведомление об этом Обществу;
                    </li>
                    <li>пользоваться всеми имеющимися на Сайте сервисами;</li>
                    <li>
                      пользоваться Сайтом исключительно в целях и порядке,
                      предусмотренных Соглашением и не запрещенных
                      законодательством Российской Федерации.
                    </li>
                  </ol>
                </li>
                <li>
                  Пользователь (субъект Персональных данных) обязан предоставить
                  полные, точные и достоверные сведения о своих Персональных
                  данных.&nbsp;
                </li>
              </ol>
            </ol>
            <p>
              <br />
            </p>
            <ol>
              <li>
                <strong>
                  ПРАВА И ОБЯЗАННОСТИ ОБЩЕСТВА ПРИ ОБРАБОТКЕ ПЕРСОНАЛЬНЫХ ДАННЫХ
                </strong>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol>
              <ol>
                <li>
                  Общество имеет право:&nbsp;
                  <ol>
                    <li>
                      обрабатывать Персональные данные Пользователя в
                      соответствии с заявленной целью;&nbsp;
                    </li>
                    <li>
                      требовать от Пользователя предоставления достоверных
                      Персональных данных, необходимых для заключения,
                      исполнения договора, оказания услуги, идентификации
                      субъекта персональных данных, а также в иных случаях,
                      предусмотренных законодательством о персональных
                      данных;&nbsp;
                    </li>
                    <li>
                      ограничить доступ Пользователя к его Персональным данным в
                      случаях, предусмотренных законодательством Российской
                      Федерации;&nbsp;
                    </li>
                    <li>
                      обрабатывать общедоступные Персональные данные физических
                      лиц;&nbsp;
                    </li>
                    <li>
                      осуществлять обработку Персональных данных, подлежащих
                      опубликованию или обязательному раскрытию в соответствии с
                      законодательством Российской Федерации;&nbsp;
                    </li>
                    <li>
                      уточнять обрабатываемые Персональные данные, блокировать
                      или удалять, если Персональные данные являются неполными,
                      устаревшими, неточными, незаконно полученными или не
                      являются необходимыми для заявленной цели обработки;&nbsp;
                    </li>
                    <li>
                      поручить обработку Персональных данных другому лицу с
                      согласия Пользователя.
                    </li>
                  </ol>
                </li>
                <li>
                  В соответствии с требованиями Закона Общество обязано:&nbsp;
                  <ol>
                    <li>
                      предоставлять Пользователю по его запросу информацию,
                      касающуюся обработки его Персональных данных, либо на
                      законных основаниях предоставить отказ;&nbsp;
                    </li>
                    <li>
                      по требованию Пользователя уточнять обрабатываемые
                      Персональные данные, блокировать или удалять, если
                      Персональные данные являются неполными, устаревшими,
                      неточными, незаконно полученными или не являются
                      необходимыми для заявленной цели обработки;&nbsp;
                    </li>
                    <li>вести учет обращений Пользователей;&nbsp;</li>
                    <li>
                      уведомлять Пользователя об Обработке персональных данных в
                      том случае, если Персональные данные были получены не от
                      Пользователя за исключением предусмотренных законом
                      Российской Федерации случаев;&nbsp;
                    </li>
                    <li>
                      в случае достижения цели обработки персональных данных
                      прекратить Обработку персональных данных и уничтожить
                      соответствующие Персональные данные в срок, не превышающий
                      тридцати дней с даты достижения цели обработки
                      Персональных данных, если иное не предусмотрено
                      соглашением с Пользователем;&nbsp;
                    </li>
                    <li>
                      в случае отзыва Пользователем согласия на обработку своих
                      Персональных данных прекратить Обработку персональных
                      данных и уничтожить персональные данные в срок, не
                      превышающий тридцати дней с даты поступления указанного
                      отзыва, если иное не предусмотрено соглашением с
                      Пользователем.&nbsp;
                    </li>
                  </ol>
                </li>
              </ol>
            </ol>
            <p>
              <br />
            </p>
            <ol>
              <li>
                <strong>
                  МЕРЫ ПО ОБЕСПЕЧЕНИЮ БЕЗОПАСНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ
                </strong>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol>
              <ol>
                <li>
                  При Обработке персональных данных Общество принимает
                  необходимые правовые, организационные и технические меры для
                  защиты Персональных данных от неправомерного или случайного
                  доступа к ним, уничтожения, изменения, блокирования,
                  копирования, предоставления, распространения персональных
                  данных, а также от иных неправомерных действий в отношении
                  персональных данных.
                </li>
              </ol>
            </ol>
            <p>
              <br />
            </p>
            <ol>
              <li>
                <strong>ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</strong>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <ol>
              <ol>
                <li>
                  Настоящая Политика является внутренним документом Общества,
                  является общедоступной и подлежит размещению на официальном
                  Сайте.
                </li>
                <li>
                  Общество имеет право вносить изменения в настоящую Политику
                  без дополнительных уведомлений. Нововведения вступают в силу с
                  момента их опубликования.
                </li>
                <li>
                  Пользователи могут отслеживать изменения в Политике
                  конфиденциальности самостоятельно.
                </li>
                <li>
                  К настоящей Политике и отношениям между Пользователем и
                  Обществом, возникающим в связи с применением Политики,
                  подлежит применению право Российской Федерации.
                </li>
              </ol>
            </ol>
          </div>
        </PopUpContainer>
  )
}

export default ConfidentialityPopUp