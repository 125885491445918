import React from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import SidebarContent from "../../components/sidebarContent";
import LoginPage from "../loginPage";
import Users from "../UsersPage/Users";
import RegistrationPage from "../registrationPage";
import { PageWrapper } from "./styles";
import PrivateRoute from "../../hocs/PrivateRoute";
import RefTree from "../RefTreePage/RefTree";
import UserInfo from "../UsersPage/UserInfo";

const MainPage: React.FC = () => {
  return (
    <div>
      <Navbar />
      <PageWrapper>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/registration" element={<RegistrationPage />} />

          <Route path="/" element={<SidebarContent />}>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <UserInfo />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <UserInfo />
                </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute>
                  <Users />
                </PrivateRoute>
              }
            />
            <Route
              path="/users/:id"
              element={
                <PrivateRoute>
                  <UserInfo />
                </PrivateRoute>
              }
            />
            <Route
              path="/ref-tree"
              element={
                <PrivateRoute>
                  <RefTree />
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </PageWrapper>
      <Footer />
    </div>
  );
};

export default MainPage;
