import styled from "styled-components";
import { styles } from "../styles/styles";

export const SidebarWrapper = styled.div`
  width: 100%;
`;

export const UserDataBox = styled.div`
  margin: 25px 10px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 576px) {
    margin: 25px 0px 40px;
  }
`;

export const UserData = styled.div`
  width: calc(100% - 100px);
`;

export const Role = styled.p`
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  @media only screen and (max-width: 1250px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 576px) {
    font-size: 25px;
  }
`;

export const FullName = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 4px 0 0;
`;

export const RatingBox = styled.div`
  align-items: flex-end;
  display: flex;
  .ant-rate-star,
  .ant-rate-star-full {
    margin-right: 4px;
  }
`;

export const RateNumber = styled.div`
  margin-left: 7px;
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
`;

export const UserAvatar = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media only screen and (max-width: 1250px) {
    width: 60px;
    height: 60px;
  }
`;

export const SwitchToBtn = styled.div`
  width: 100%;
  padding: 14px 21px;
  text-align: center;
  background-color: ${styles.mainColor};
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: white;
  display: none;
  @media only screen and (max-width: 576px) {
    display: block;
  }
`;

export const NavsListWrapper = styled.div``;

interface NavProps {
  active: boolean;
}

export const NavItem = styled.div`
  display: flex;
  text-decoration: none;
  justify-content: space-between;
  color: black;
  padding: 14px 10px;
  margin: 10px 0;
  background: ${({ active }: NavProps) =>
    active ? "#FCE1D8" : "transparent"};
  border-radius: 8px;
  p {
    color: black;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  div {
    display: flex;
  }
  @media only screen and (max-width: 576px) {
    padding: 14px 0px;
    border-bottom: 1px solid #ebebeb;
    border-radius: 0;
    background: transparent;
  }
`;
