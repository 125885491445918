
// const ENDPOINT = "http://r.rukki.pro";
const ENDPOINT = "https://api.rukki.pro";
// const ENDPOINT = "http://localhost:3001";
const BASE_URL = `${ENDPOINT}/api`;
const SEARCH_ADDRESS_URL = "https://search-maps.yandex.ru/v1/";
const YANDEX_PLACE_APIKEY = "83521ae3-4f3e-491a-b483-7ccb9b1d163e";
// process.env.REACT_APP_YANDEX_PLACE_APIKEY;

export { BASE_URL, SEARCH_ADDRESS_URL, YANDEX_PLACE_APIKEY };
