import {Link} from "react-router-dom";
import styled from "styled-components";

export const VehicleWrapper = styled.div`
  width: 100%;
  position: relative;
  border: 1px solid grey;
  padding: 20px 30px ;
  border-radius: 10px;
  margin-top: 35px;

  button {
    margin: 0 auto 0 0;
    padding: 8px 24px;
  }

  @media only screen and (max-width: 576px) {
    padding-bottom: 30px;
    /* button {
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      border-radius: 0;
    } */
  }
`;

export const VehicleBox = styled.div`
  padding: 34px 0 0;

  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
  }
`;

export const VehicleList = styled.div``;

export const VehicleItem = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  padding: 16px 0;
  cursor: pointer;
  color: black;

  .number {
    font-size: 20px;
    padding: 0 15px 0 0;
  }

  &:last-child {
    border: 0;
  }

  .data {
    margin-right: auto;
  }

  .view {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
  }

  .name {
    margin-right: auto;
    color: black;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.543846);
  }

  .number {
    color: black;
  }

  .info-required {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #ff3358;
    margin-right: 10px;
  }

  .arrow-right {
    display: flex;
    margin-left: 10px;
  }
`;
export const HintBox = styled.div`
  position: absolute;
  top: 10px;
  ${({left}: { left?: boolean }) => left ? "left: 10px;" : "right: 10px;"}
  width: fit-content;
  height: fit-content;
  display: flex;

  svg {
    cursor: pointer;
  }
`;

export const HintContent = styled.div`
  width: 250px;
`;