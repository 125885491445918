import { Form, Input, Button, Select, DatePicker } from "antd";
import React, { useState } from "react";
import russianCities from "../../../../../constants/russian-cities.json";
import "react-phone-input-2/lib/style.css";
import { HeaderGroupRight } from "./styles";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

type Loading = "idle" | "pending" | "succeeded" | "failed";
interface Props {
  form: any;
  onFinish: any;
  onFinishFailed: any;
  loading: Loading;
  currentUser: any;
}

const UserInfoFormRight: React.FC<Props> = ({
  form,
  onFinish,
  onFinishFailed,
  loading,
  currentUser,
}) => {
  const dateFormat = "YYYY/MM/DD";
  const navigate = useNavigate();

  return (
    <Form
      form={form}
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 17, offset: 0 }}
      labelAlign="left"
      size="large"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <HeaderGroupRight>
        <Form.Item>
          <div style={{ display: 'flex', gap: '5%', justifyContent: 'space-between', alignItems: 'start' }}>
          {currentUser?.customer?.isStatsAdmin === 1 && <Button
            size="large"
            onClick={() => navigate('/users')}
            disabled={loading === "pending"}
            htmlType="button"
          >
            Пользователи
          </Button>}
          <Button
            // block
            size="large"
            // onClick={onSubmitUpdateUser}
            disabled={loading === "pending"}
            htmlType="submit"
          >
            Обновить/Сохранить
          </Button>
          </div>
        </Form.Item>
      </HeaderGroupRight>
      <Form.Item
        name="birthday"
        label="День рождения"
        rules={[{ required: true }]}
      >
        <DatePicker format={dateFormat} style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="city"
        label="Город"
        rules={[
          {
            required: true,
            message: "Выберите город",
          },
        ]}
      >
        <Select placeholder="Выберите город">
          {russianCities.map((item: any, index: number) => {
            return (
              <Option key={`city${index}`} value={item.name}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name="phoneNumber"
        label="Номер телефона"
        rules={[
          {
            required: true,
            message: "Заполните это поле",
          },
        ]}
      >
        {/* <PhoneInput
          onlyCountries={["ru"]}
          value={form.getFieldValue("phoneNumber")}
          inputStyle={{
            height: "43px",
            boxSizing: "border-box",
            width: "219px",
            border: "0px",
          }}
        /> */}
        <Input type="string" placeholder="Номер телефона" disabled />
      </Form.Item>
      <Form.Item
        name="status"
        label="Статус"
        rules={[
          {
            required: true,
            message: "Выберите значение",
          },
        ]}
      >
        <Select placeholder="Роль" disabled>
          <Option value="active">Доступен</Option>
          <Option value="disactive">Заблокирован</Option>
          <Option value="new">Новый</Option>
        </Select>
      </Form.Item>
    </Form>
  );
};

export default UserInfoFormRight;
