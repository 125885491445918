import { createSlice } from '@reduxjs/toolkit';

interface UiInterface {
  sideBar: boolean;
}

const initialState:UiInterface = {
  sideBar: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setSidebar(state) {
      state.sideBar = !state.sideBar;
    },
  },
});

export const { setSidebar } = uiSlice.actions;

export default uiSlice.reducer;