import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDYqZxNszPD0rIXieyo_gwWul63t2sVJ3c",
  authDomain: "rukki-pro.firebaseapp.com",
  databaseURL: "https://rukki-pro.firebaseio.com",
  projectId: "rukki-pro",
  storageBucket: "rukki-pro.appspot.com",
  messagingSenderId: "1001064264116",
  appId: "1:1001064264116:web:e9306364c0b087c4a7c2e1",
};

export const firebase = initializeApp(firebaseConfig);
export const authentication = getAuth(firebase);
