import { Link } from "react-router-dom";
import styled from "styled-components";
import { Container, styles } from "../styles/styles";

export const NavbarWrapper = styled.nav`
  width: 100%;
  height: 80px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.12));
  @media only screen and (max-width: 768px) {
    height: 60px;
  }
  @media only screen and (max-width: 576px) {
    padding: 0 20px;
  }
`;

export const NavbarContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-wrap: wrap; */
`;

export const Logo = styled(Link)`
  display: block;
  height: 50px;
  img {
    height: 100%;
  }
  @media only screen and (max-width: 768px) {
    height: 32px;
  }
`;

export const LoginBtn = styled.button`
  width: 130px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${styles.mainColor};
  color: white;
  border-radius: 10px;
  font-size: 13px;
  border: 0;
  cursor: pointer;
`;

export const AvatarBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
  .name {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    color: #222222;
    display: flex;
    align-items: center;

    .anticon,
    .anticon-down {
      margin-left: 5px;
    }
  }
`;
