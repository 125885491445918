import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {httpRequest} from '../../../utils/httpRequest';

type Loading = 'idle' | 'pending' | 'succeeded' | 'failed';

interface State {
  entities: {
    users: any[],
    total: number,
    current: number
  };
  currentUser: any | null;
  parrentUser: any | null;
  loading: Loading;
  error: unknown;
  loadingParrentUser: Loading;
}


export const getReferals = createAsyncThunk(
    'referals/getReferals',
    async ({current, pageSize, navigate}: { current: number, pageSize: number, navigate: any }, thunkApi: any) => {
      try {
        const refId = thunkApi.getState()?.auth?.user?.customer?.refId;

        console.log(thunkApi.getState()?.auth?.user?.customer?.refId)

        const response = await httpRequest({
          method: "GET",
          path: `analytics/${refId}/users?page=${current}&size=${pageSize}`,
        });

        return response.data;
      } catch (err: any) {
        const {status, statusText} = err.response;
        console.log(status, statusText);
      }

    }
);


export const getRefUserById = createAsyncThunk(
    'referals/getRefUserById',
    async (id: number, thunkApi: any) => {
      try {
        const refId = thunkApi.getState()?.auth?.user?.customer?.refId;

        const response = await httpRequest({
          method: "GET",
          path: `analytics/${refId}/users/${id}`,
        });
        return response.data;
      } catch (err) {
        return "Error code here";
      }

    }
);

export const getParrentUserById = createAsyncThunk(
    'referals/getParrentUserById',
    async (id: number, thunkApi: any) => {
      try {
        const refId = thunkApi.getState()?.auth?.user?.customer?.refId;

        const response = await httpRequest({
          method: "GET",
          path: `analytics/${refId}/users/${id}`,
        });
        return response.data;
      } catch (err) {
        return "Error code here";
      }

    }
);

export const updateUserData = createAsyncThunk(
    'referals/getParrentUserById',
    async ({id, data}: { id: number; data: any }, thunkApi: any) => {
      try {
        const refId = thunkApi.getState()?.auth?.user?.customer?.refId;

        const response = await httpRequest({
          method: "PUT",
          path: `analytics/${refId}/users/${id}`,
          data
        });
        console.log('Updade user data', response);

        if (data && data.vehId && data.vehId !== null) {
          const responseVehicles = await httpRequest({
            method: "PUT",
            path: `analytics/vehicles/${data.vehId}`,
            data: {
              categoryId: data.vehCatId,
              brand: data.vehBrand,
              model: data.vehModel,
              stateNumber: data.vehStateNumber,
              comment: ""
            }
          });

          console.log('Updade user responseVehicles', responseVehicles);
        }

        if (data.inn && data.bik && data.rs && data.ks) {

          const responseRequisites = await httpRequest({
            method: "PUT",
            path: `analytics/requisites/${id}`,
            data: {
              inn: data.inn,
              bik: data.bik,
              rs: data.rs,
              ks: data.ks
            }
          });

          console.log('Updade user responseRequisites', responseRequisites);
        }

        await thunkApi.dispatch(getRefUserById(id))
        // return response.data;
      } catch (err: any) {
        console.log('responseRequisites err', err.response.statusText = "Not Found")
        if (err.response.statusText === "Not Found") {
          if (data.inn && data.bik && data.rs && data.ks) {

            const responseRequisites = await httpRequest({
              method: "POST",
              path: `analytics/requisites/${id}`,
              data: {
                inn: data.inn,
                bik: data.bik,
                rs: data.rs,
                ks: data.ks
              }
            });

            console.log('Updade user responseRequisites', responseRequisites);
          }

          await thunkApi.dispatch(getRefUserById(id))
        }
      }

    }
);

const initialState: State = {
  entities: {
    users: [],
    total: 0,
    current: 1
  },
  currentUser: null,
  loading: 'idle',
  parrentUser: null,
  loadingParrentUser: 'idle',
  error: null
}

export const referalsSlice = createSlice({
  name: 'referals',
  initialState,
  reducers: {
    clearReferal(state) {
      state.entities = {
        users: [],
        total: 0,
        current: 1
      }
      state.loading = 'pending';
      state.error = null;
    },
    clearParrentUser(state) {
      state.parrentUser = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getReferals.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = 'succeeded';
    });
    builder.addCase(getReferals.pending, (state) => {
      state.error = null;
      state.loading = 'pending'
    });
    builder.addCase(getReferals.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = 'failed';
      state.entities = {
        users: [],
        total: 0,
        current: 1
      }
    });
    builder.addCase(getRefUserById.fulfilled, (state, action) => {
      state.currentUser = action.payload;
      state.loading = 'succeeded';
    });
    builder.addCase(getRefUserById.pending, (state) => {
      state.error = null;
      state.loading = 'pending'
    });
    builder.addCase(getRefUserById.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = 'failed';
      state.currentUser = null;
    });
    builder.addCase(getParrentUserById.fulfilled, (state, action) => {
      state.parrentUser = action.payload;
      state.loadingParrentUser = 'succeeded';
    });
    builder.addCase(getParrentUserById.pending, (state) => {
      state.error = null;
      state.loadingParrentUser = 'pending'
    });
    builder.addCase(getParrentUserById.rejected, (state, action) => {
      state.error = action.payload;
      state.loadingParrentUser = 'failed';
      state.parrentUser = null;
    });

  },
});

export const {clearReferal, clearParrentUser} = referalsSlice.actions;

export default referalsSlice.reducer;