import styled from "styled-components";

export const PopUpWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 999;
`;

export const PopUpBackground = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.55);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const PopUpBox = styled.div`
  width: ${({ middle, big }: { middle?: boolean; big?: boolean }) =>
    big ? "600px" : middle ? "480px" : "360px"};
  height: fit-content;
  max-height: 90vh;
  overflow: auto;
  background-color: white;
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 32px 24px;
  border-radius: 20px;
  .button {
    margin-top: 20px;
  }

  @media only screen and (max-width: 650px) {
    width: ${({ middle, big }: { middle?: boolean; big?: boolean }) =>
      big ? "90%" : middle ? "480px" : "360px"};
  }

  @media only screen and (max-width: 530px) {
    width: ${({ middle, big }: { middle?: boolean; big?: boolean }) =>
      big ? "90%" : middle ? "90%" : "360px"};
  }

  @media only screen and (max-width: 400px) {
    width: 90%;
    height: 90%;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${({ mb }: { mb: any }) => (mb ? mb : "20px")};
  justify-content: space-between;
  .title {
    width: calc(100% - 65px);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  .close {
    width: 57px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
  }
`;
