import React, { useState } from "react";
import {
  FooterContainer,
  FooterWrapper,
  Logo,
  Navs,
  NavsWrapper,
  SocialMediaLogo,
  SocialMediaWrapper,
} from "./styles";
import logo from "../../assets/images/bw-logo.png";
import { ReactComponent as InstagramLogo } from "../../assets/icons/instagram.svg";
import { ReactComponent as FacebookLogo } from "../../assets/icons/facebook.svg";
import { ReactComponent as YouTubeLogo } from "../../assets/icons/youtube.svg";
import ConfidentialityPopUp from "../popUp/confidentialityPopUp";
import UserAgreementPopUp from "../popUp/userAgreementPopUp";

const Footer: React.FC = () => {
  const socialMedias = [
    {
      icon: <YouTubeLogo />,
      link: "https://www.youtube.com/channel/UCGuavrdpoW8BvdhfgfGmdTg",
    },
    {
      icon: <InstagramLogo />,
      link: "https://www.instagram.com/rukki.pro",
    },
    {
      icon: <FacebookLogo />,
      link: "https://www.facebook.com/rukki.pro",
    },
  ];

  const [modal, setModal] = useState({
    userAgreement: false,
    confidentiality: false,
  });

  return (
    <FooterWrapper id="footer">
      <FooterContainer>
        <Logo>
          <img src={logo} alt="" />
        </Logo>

        <Navs>
          <NavsWrapper>
            <div
              // target={"_blank"}
              // rel="noreferrer"
              // href="https://docs.yandex.ru/docs/view?url=ya-disk-public%3A%2F%2FdzJQqAX2BJL3NjyWe9IFjMAIPKemLZ4g1CyYtQIfQaM4jJM6UW1uNTVUm3FmHVnSq%2FJ6bpmRyOJonT3VoXnDag%3D%3D&name=PersonalData.docx&nosw=1"
              onClick={() => setModal({ ...modal, userAgreement: true })}
            >
              Пользовательское соглашение
            </div>
            <div
              // target={"_blank"}
              // rel="noreferrer"
              // href="https://docs.yandex.ru/docs/view?url=ya-disk-public%3A%2F%2Frz4IZA7qt%2FsAEri6sckegTWgxi4EiBzadNy%2FV56IIaQrvtfn75c7lTahICT2KKPCq%2FJ6bpmRyOJonT3VoXnDag%3D%3D&name=PrivacyPolicy%20(1).docx"
              onClick={() => setModal({ ...modal, confidentiality: true })}
            >
              Конфиденциальность
            </div>
          </NavsWrapper>
          <SocialMediaWrapper>
            {socialMedias.map(({ icon, link }, index) => (
              <SocialMediaLogo
                target={"_blank"}
                rel="noreferrer"
                key={index}
                href={link}
              >
                {icon}
              </SocialMediaLogo>
            ))}
          </SocialMediaWrapper>
        </Navs>
      </FooterContainer>
      {modal.userAgreement && (
        <UserAgreementPopUp
          close={() => setModal({ ...modal, userAgreement: false })}
        />
      )}
      {modal.confidentiality && (
        <ConfidentialityPopUp
          close={() => setModal({ ...modal, confidentiality: false })}
        />
      )}
    </FooterWrapper>
  );
};

export default Footer;
