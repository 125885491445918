import {Input as InputAntd, Button as ButtonAntd, Select as SelectAntd, Form} from "antd";
import styled from "styled-components";

// export const FormGroup = styled(Form.Item)`
//     width: 100%;
//     display: flex;
//     flex-flow: row nowrap;
// `;

export const styles = {
  mainColor: "#FF6633",
  red: "rgba(255, 51, 88, 1)",
};

export const FormGroup = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
`;

export const Half = styled.div`
  margin-top: 35px;
  width: 100%;
  @media only screen and (max-width: 1250px) {
    width: 100%;
  }
  @media only screen and (max-width: 1050px) {
    width: 100%;
  }
`;

export const DFlex = styled.div`
  border: 1px solid grey;
  border-radius: 10px;
  padding: 20px 30px;
  gap: 5%;
  max-width: 750px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  @media only screen and (max-width: 1050px) {
    flex-direction: column;
  }
`;

export const PromoCodeTitle = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  @media only screen and (max-width: 1050px) {
    margin-top: 25px;
  }
`;

export const PromoCode = styled.p`
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
`;

export const PromoCodeBox = styled.div`
  margin: 4px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CopyPromoCode = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  /* transition-duration: 0.1s; */

  svg {
    margin-right: 6px;
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const SocialMediaWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SocialMediaBox = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  margin: 5px;

  button {
    display: flex;
    border-radius: 100%;
    overflow: hidden;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Label = styled.div`
  background-color: #FCE1D8;
  height: 100%;
  flex: 0 0 192px;
  display: flex;
  align-items: center;
  line-height: 32px;
  padding: 5px 22px;
  border-bottom: 1px solid #EEB8A7;
  box-shadow: 0px 10px 22px rgba(118, 116, 115, 0.12);
  box-sizing: border-box;
`;

export const Input = styled(InputAntd)`
  border: 1px solid transparent;
  /* margin-left: 9px; */
  margin-bottom: 2px;
  box-sizing: border-box;
`

export const Select = styled(SelectAntd)`
  /* margin-left: 9px; */
  /* padding: 4px 11px; */
  /* margin-bottom: 0px; */
  width: 100%;
  border: 0px;
  flex: 1 22px;
  box-sizing: border-box;
  /* margin: 0; */
  /* height: 40px; */
  /* border: none; */
  /* margin-left: 9px; */
  height: 41px;
`

export const Button = styled(ButtonAntd)`
  border-color: #FF6633;
  background: #FF6633;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  color: #fff;
  border-radius: 8px;

  &:hover {
    opacity: 0.5;
    border-color: #FF6633;
    background: #FF6633;
    color: #fff;
  }
`

export const HorizontalWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`

export const HeaderGroup = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
`

export const HeaderGroupRight = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
`

export const VehicleWrapper = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`
export const ContentTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;


export const Earned = styled.div`
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 10px;
`;

export const ShareBtn = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 37px;
  width: 100%;
  border: 1px dashed ${styles.mainColor};
  border-radius: 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-top: 24px;
  cursor: pointer;

  svg {
    margin-right: 11px;
  }

  &:hover {
    background-color: #00000011;
  }

  &:active {
    background-color: transparent;
  }

  @media only screen and (max-width: 576px) {
  }
`;

export const SmallText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #222222;

  mix-blend-mode: normal;
  opacity: 0.5;
`;