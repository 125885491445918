import React, { useEffect, useState } from "react";
import { Divider, List, Typography, Col } from "antd";
import { useNavigate } from "react-router-dom";
import UserHeader from "../../UserHeader/UserHeader";
import { clearParrentUser, getParrentUserById } from "../../../../redux/store/reducers/referalsSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { Button } from "./style";
import UserInfoForm from "./UserInfoForm";
import { httpRequest } from "../../../../utils/httpRequest";

interface TInfo {
  title: string;
  value: string | number;
  titleKey: string;
}

interface Props {
  refList: "agent" | "worker" | "";
  info: TInfo[];
  onClickShowRefList: (e: any) => void;
  parentUser: any;
  registrations: any[];
  role: string;
  transactionsCount: string;
  currentUser: any;
}

const getCustomerName = (user: any): string => {


  if (!user) return "нет вышестоящего агента";
  
  const firstName = user?.customer?.firstName;
  const secondName = user?.customer?.secondName;
  const patronymic = user?.customer?.patronymic;
  const name = `${firstName || ""} ${secondName || ""} ${patronymic || ""}`;
  return name;
};

const UserInfoLeftSide: React.FC<Props> = ({
  refList,
  info,
  onClickShowRefList,
  parentUser,
  registrations,
  role,
  currentUser,
  transactionsCount,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [refLevel, setRefLevel] = useState<TInfo[]>([]);
  //const currentUser = useAppSelector(state => state.referals.currentUser);
  // useEffect(() => {
  //   return () => {
  //     dispatch(clearParrentUser());
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  console.log(currentUser)

  useEffect(() => {
    if (registrations && registrations.length > 0) {
      const result = registrations;
      const arrayData = [];
      console.log("result", result);
      const workerCount = result.filter(
        (item: any) => item.role === "worker"
      );
      const agentCount = result.filter((item: any) => item.role === "agent");
      if (workerCount.length > 0)
        arrayData.push({
          title: "Исполнитель",
          value: workerCount.length,
          titleKey: "worker",
        });
      if (agentCount.length > 0)
        arrayData.push({
          title: "Агент",
          value: agentCount.length,
          titleKey: "agent",
        });
      setRefLevel(arrayData);
    }
  }, [registrations]);

  console.log("registrations", registrations);
  return (
    <Col span={24}>
      <UserHeader
        id={parentUser?.customer?.id}
        close={() => navigate(`/`)}
        title={getCustomerName(parentUser)}
        showTitle={true}
      />
      <UserInfoForm info={info} currentUser={currentUser}/>
    </Col>
  );
};

export default UserInfoLeftSide;
