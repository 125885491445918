import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
`;

export const UserInfoContainer = styled.div`
  width: 50%;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 20px;
`;

export const InfoWrapper = styled.div`
  flex: 1;
  display: flex;  
  flex-flow: row nowrap;
`;

export const InfoData = styled.div`
  flex: 1;
  display: flex;  
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: rgb(0, 0, 0, 0.1);
  margin-right: 5px;
  margin-bottom: 5px;
`;

export const InfoTitle = styled.div`
  flex: 1;
  display: flex;  
  justify-content: left;
  align-items: center;
  padding: 5px;
  background-color: rgb(0, 0, 0, 0.1);
  margin-right: 5px;
  margin-bottom: 5px;
  font-weight: bold;
`;

export const Button = styled.button`
  border: none;
  outline: none;
  background: #FF6633;
  padding: 10px 15px;
  color: #fff;
  border-radius: 8px;
  transition: all 0.25s linear;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;

  &:hover {
    opacity: 0.5;
  }
`;
