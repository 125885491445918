import React from "react";

import { StatusBox } from "./styles";

import { ReactComponent as FulfilledIcon } from "../../assets/icons/Check.svg";
import { ReactComponent as RejectedIcon } from "../../assets/icons/close-red-circle.svg";
import { ReactComponent as PendingIcon } from "../../assets/icons/waiting.svg";
import { ReactComponent as EmptyIcon } from "../../assets/icons/circle-black-border.svg";

interface RejectedProps {
  status: string;
  reason: string;
}

const RejectedStatus: React.FC<RejectedProps> = ({
  status,
  reason,
}: {
  status: string;
  reason: string;
}) => {
  return (
    <>
      {status === "pending" ? (
        <StatusBox>
          <div className="status-part">
            <PendingIcon />
          </div>
          <div className="text-part">
            Ваш документ загружен и ожидает проверки.
          </div>
        </StatusBox>
      ) : status === "fulfilled" ? (
        <StatusBox>
          <div className="status-part">
            <FulfilledIcon />
          </div>
          <div className="text-part">
            Ваш документ проверен, теперь вы сможете пользоваться сервисом.
          </div>
        </StatusBox>
      ) : status === "rejected" ? (
        <StatusBox>
          <div className="status-part">
            <RejectedIcon />
          </div>
          <div className="text-part">
            Ваш документ не принят. Вы не сможете пользоваться сервисом, пока не
            подтвердите допуск к работам. <br />{" "}
            {reason && (
              <span style={{ color: "red" }}>Причина отказа: {reason}</span>
            )}
          </div>
        </StatusBox>
      ) : status === "empty" ? (
        <StatusBox>
          <div className="status-part">
            <EmptyIcon />
          </div>
          <div className="text-part">
            Загрузите документ для прохождения проверки
          </div>
        </StatusBox>
      ) : (
        ""
      )}
    </>
  );
};

export default RejectedStatus;
