import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Typography,
  Space,
  Image,
  Divider,
} from "antd";
import { updateCurrentUser } from "firebase/auth";
import React, { useEffect, useState } from "react";
// import Input from "../../../../../components/Input";
import russianCities from "../../../../../constants/russian-cities.json";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import TextArea from "antd/lib/input/TextArea";
import { HorizontalWrapper } from "./styles";
import VehiclePage from "../../../../vehiclePage";

const { Option } = Select;

interface Props {
  form: any;
  onFinish: any;
  onFinishFailed: any;
}

const UserInfoFormBankData: React.FC<Props> = ({
  form,
  onFinish,
  onFinishFailed,
}) => {
  return (
    <>
      <Form
        form={form}
        labelAlign="left"
        size="large"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {/* <Typography.Title level={4}>Комментарии</Typography.Title>
        <Form.Item name="comments">
          <TextArea rows={4} />
        </Form.Item> */}
        <Typography.Title level={4}>Заказы</Typography.Title>
        {/* <Form.Item name="totalPaid" label="Количество предложенных"> */}
        <Form.Item name="totalPaid" label="Заработано всего">
          <Input type="string" disabled />
        </Form.Item>
        <Form.Item name="transactionsCount" label="Количество выполненных">
          <Input type="string" disabled />
        </Form.Item>
        {/* <Typography.Title level={4}>Баланс</Typography.Title>
        <Form.Item name="commission" label="Комиссия">
          <Input placeholder="Процент" addonAfter="%" />
        </Form.Item> */}
        <Typography.Title level={4}>Реквизиты</Typography.Title>
        <HorizontalWrapper>
          <Form.Item name="inn" label="ИНН">
            <Input />
          </Form.Item>
          <Form.Item name="bik" label="БИК">
            <Input />
          </Form.Item>
        </HorizontalWrapper>
        <HorizontalWrapper>
          <Form.Item name="rs" label="Р/C">
            <Input />
          </Form.Item>
          <Form.Item name="ks" label="К/C">
            <Input />
          </Form.Item>
        </HorizontalWrapper>
      </Form>
      <VehiclePage/>
    </>
  );
};

export default UserInfoFormBankData;
