import React from "react";
import NumberFormat from "react-number-format";
import { InputWrapper } from "./styles";

export const Form: React.FC = () => {
  return <div>Form</div>;
};

export const InputBox: React.FC<any> = ({
  label,
  type,
  children,
  prefix,
  required,
  ...props
}) => {
  return (
    <InputWrapper>
      <label>
        {label} <span className="required">{required ? "*" : ""}</span>
      </label>
      <div style={{ marginBottom: "25px" }}>
        {prefix || ""}

        {children ? (
          children
        ) : type === "tel" ? (
          <NumberFormat
            format={"+7 (###) ###-##-##"}
            placeholder="+7"
            defaultValue={""}
            {...props}
          />
        ) : type === "b-day" ? (
          <NumberFormat
            format={"##/##/####"}
            placeholder="01/01/1970"
            {...props}
          />
        ) : type === "card-number" ? (
          <NumberFormat
            format={"#### #### #### ####"}
            placeholder="0000 0000 0000 0000"
            {...props}
          />
        ) : type === "expire-date" ? (
          <NumberFormat format={"##/##"} placeholder="ММ/ГГ" {...props} />
        ) : type === "cvc" ? (
          <NumberFormat format={"###"} placeholder="" {...props} />
        ) : type === "textarea" ? (
          <textarea placeholder="Подробный отзыв" rows={3} {...props} />
        ) : (
          <input type="text" {...props} />
        )}
      </div>
    </InputWrapper>
  );
};
