import React, {memo, useEffect, useState} from "react";
import {PinInputActions, usePinInput} from "react-pin-input-hook";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {setAlternativeAuth, setCall,} from "../../redux/store/reducers/authSlice";
import {RootState} from "../../redux/store/store";
import {FormDesc, FormTitle} from "../form/styles";
import {CodeSendTo, DidNotReceiveCode, ErrorMsg, PinInput, PinInputField, TimeRemained,} from "./styles";

interface Props {
  phoneNumber: any;
  setOtp: (otp: string) => void;
  verifyOtp: (otp: string) => void;
  error: string;
  verifyLoading: boolean;
  smsRu: boolean;
  setFlag: (x: any) => void;
  tryToLogin: any;
  setCallHandler: any;
}

const VerifyCodeForm: React.FC<Props> = (
    {
      phoneNumber,
      verifyOtp,
      error,
      verifyLoading,
      smsRu,
      setFlag,
      tryToLogin,
      setCallHandler,
    }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const actionRef = React.useRef<PinInputActions>(null);
  const {loadingAltAuth, alternativeAuth, isCall} = useAppSelector(
      (state: RootState) => state.auth
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // const [values, setValues] = useState(Array(6).fill(""));
  const [values, setValues] = useState(Array(alternativeAuth ? 4 : 6).fill(""));

  const {fields} = usePinInput({
    values,
    otp: true,
    onChange: (values) => {
      setValues(values);
    },
    onComplete: (value) => {
      verifyOtp(value);
    },
    actionRef,
    placeholder: "•",
  });

  const [timer, setTimer] = useState(30);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [codeError, setCodeError] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimer(timer - 1);
    }, 1000);
    if (timer === 0) {
      // dispatch(setCall(false));
      clearTimeout(timeout);
      // setFlag(false);
      // navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  const [myOtp, setMyOtp] = useState("");

  useEffect(() => {
    if ("OTPCredential" in window) {
      // const ac = new AbortController();

      navigator.credentials
          .get({
            // @ts-ignore
            otp: {transport: ["sms"]},
            // signal: ac.signal,
          })
          .then((otp: any) => {
            const code = otp.code;
            setMyOtp(code);
            code &&
            Number(code) >= 100000 &&
            Number(code) <= 999999 &&
            setValues(String(code).split(""));
            // ac.abort();
          })
          .catch((err) => {
            // ac.abort();
            console.log("OTP error");
            console.log(err);
          });
    }
    // return () => setFlag(true);
  }, []);

  const login = () => {
    return new Promise((resolve, reject) => {
      const newVal = Array(4).fill("");
      setValues(newVal);
      dispatch(setAlternativeAuth(true));
      if (isCall) dispatch(setCall(false));
      return resolve(true);
    });
  };

  return (
      <>
        <FormTitle>Проверочный код</FormTitle>
        <FormDesc>Мы отправили вам код для проверки номера</FormDesc>
        {/* <b>{myOtp}</b> */}

        {codeError && (
            <ErrorMsg>
              Это неверный код. Проверьте сообщение и попробуйте снова.
            </ErrorMsg>
        )}
        <PinInput className="pin-input">
          {fields.map((propsField, index) => (
              <PinInputField
                  error={codeError}
                  key={index}
                  className="pin-input__field"
                  {...propsField}
                  disabled={verifyLoading || loadingAltAuth === "pending"}
                  style={{color: error !== "" ? "red" : "inherit"}}
              />
          ))}
        </PinInput>
        <div
            style={{
              color: "#ff6633",
              textDecoration: "underline",
              paddingTop: "10px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => {
              setFlag(false);
              dispatch(setAlternativeAuth(false));
              dispatch(setCall(true));
              localStorage.setItem("phoneNumberInLogin", phoneNumber);
              window?.location?.reload();
            }}
        >
          Вернуться к набору номера
        </div>
        {error !== "" && (
            <div style={{color: "red", margin: "20px 0", fontWeight: "bold"}}>
              {error}
            </div>
        )}
        <CodeSendTo style={{marginTop: "32px"}}>
          Отправлено на{" "}
          {
            phoneNumber
            // ?.formattedValue
          }
        </CodeSendTo>
        <DidNotReceiveCode>
          Не получили код?{" "}
          {timer === 0 && (
              <>
            <span
                style={{fontWeight: "bold"}}
                onClick={() => {
                  const newVal = Array(4).fill("");
                  setValues(newVal);
                  dispatch(setAlternativeAuth(true));
                  // });
                }}
            >
              Нажмите чтобы отправить {isCall ? "звонок" : "смс"} на ваш телефон
              (альтернативная авторизация)
            </span>
                {alternativeAuth && (
                    <span
                        style={{
                          fontWeight: "bold",
                          color: "#ff6633",
                          textDecoration: "underline",
                          paddingLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          tryToLogin();
                          if (isCall && alternativeAuth) dispatch(setCall(false));
                          setTimer(30);
                        }}
                    >
                {isCall ? "Позвонить" : "Отправить смс"}
              </span>
                )}
              </>
          )}
        </DidNotReceiveCode>
        <TimeRemained>
          Выслать повторно через <span>({timer} сек)</span>
        </TimeRemained>
      </>
  );
};

export default memo(VerifyCodeForm);



// import React, { useEffect, useState } from "react";
// import { usePinInput, PinInputActions } from "react-pin-input-hook";
// import { useSearchParams } from "react-router-dom";
// import { FormDesc, FormTitle } from "../form/styles";
// import {
//   CodeSendTo,
//   DidNotReceiveCode,
//   ErrorMsg,
//   PinInput,
//   PinInputField,
//   TimeRemained,
// } from "./styles";

// interface Props {
//   phoneNumber: any;
//   setOtp: (otp: string) => void;
//   verifyOtp: (otp: string) => void;
//   error: string;
// }

// const VerifyCodeForm: React.FC<Props> = ({ phoneNumber, verifyOtp, error }) => {
//   const [searchParams, setSearchParams] = useSearchParams();
//   const actionRef = React.useRef<PinInputActions>(null);

//   const [values, setValues] = useState(Array(6).fill(""));

//   const { fields } = usePinInput({
//     values,
//     otp: true,
//     onChange: (values) => {
//       setValues(values);
//     },
//     onComplete: (value) => {
//       verifyOtp(value);
//     },
//     actionRef,
//     placeholder: "•",
//   });

//   const [timer, setTimer] = useState(59);
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   const [codeError, setCodeError] = useState(false);

//   useEffect(() => {
//     const timeout = setTimeout(() => {
//       setTimer(timer - 1);
//     }, 1000);
//     if (timer === 0) {
//       clearTimeout(timeout);
//     }
//   }, [timer]);

//   return (
//     <>
//       <FormTitle>Проверочный код</FormTitle>
//       <FormDesc>Мы отправили вам код для проверки номера</FormDesc>

//       {codeError && (
//         <ErrorMsg>
//           Это неверный код. Проверьте сообщение и попробуйте снова.
//         </ErrorMsg>
//       )}
//       <PinInput className="pin-input">
//         {fields.map((propsField, index) => (
//           <PinInputField
//             error={codeError}
//             key={index}
//             className="pin-input__field"
//             {...propsField}
//             disabled={false}
//             style={{ color: error !== "" ? "red" : "inherit" }}
//           />
//         ))}
//       </PinInput>
//       {error !== "" && (
//         <div style={{ color: "red", margin: "20px 0", fontWeight: "bold" }}>
//           {error}
//         </div>
//       )}
//       <CodeSendTo style={{ marginTop: "32px" }}>
//         Отправлено на {phoneNumber?.formattedValue}
//       </CodeSendTo>
//       <DidNotReceiveCode
//         onClick={() => {
//           searchParams.delete("nav");
//           setSearchParams(searchParams);
//           window.location.reload();
//         }}
//       >
//         Не получили код?
//       </DidNotReceiveCode>
//       <TimeRemained>
//         Выслать повторно <span>({timer} сек)</span>
//       </TimeRemained>
//     </>
//   );
// };

// export default VerifyCodeForm;
