import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PageContainer from "../../components/pageContainer";
import { FormWrapper, LoginWrapper, Wrapper } from "./styles";
import LoginForm from "../../components/loginForm";
import VerifyCodeForm from "../../components/verifyCodeForm";
import RegistrationPage from "../registrationPage";
import { useGlobalContext } from "../../App";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import {
  fetchAuthCall,
  fetchAuthSms,
  fetchAuthVerify,
  getRefIDFromFireBase,
  setAlternativeAuth,
  setCall,
  setUserPhoneNumber,
} from "../../redux/store/reducers/authSlice";
// import countries from "../../constants/world.json";
// import LoginHint from "../../components/hints/loginHint";
// import RegistrationHint from "../../components/hints/registrationHint";

const firebaseError = (error: string): string => {
  if (error.includes("(auth/invalid-phone-number)"))
    return "Неверный номер телефона, попробуйте еще раз";
  if (error.includes("(auth/too-many-requests)"))
    return "Ошибка, слишком много попыток аутентификации";
  if (error.includes("(auth/argument-error)"))
    return "Вы не прошли проверку, попробуйте еще раз";
  if (error.includes("(auth/invalid-verification-code)"))
    return "Неправильный код подверждения, попробуйте еще раз";
  return "Ошибка авторизации: неизвестная ошибка, отправьте запрос еще раз!";
};

const LoginPage: React.FC<any> = ({setDisable}) => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { userId, loadingAltAuth, altAuth } = useAppSelector(
    (state: RootState) => state.auth
  );
  const { setUpRecaptcha } = useGlobalContext();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [otp, setOtp] = useState<any>("");
  const [error, setError] = useState<any>("");
  const [result, setResult] = useState<any>("");
  const [flag, setFlag] = useState<boolean>(false);
  const { fireBaseData, alternativeAuth, isCall, loadingRef } = useAppSelector(
    (state: RootState) => state.auth
  );
  const myDispatch = useAppDispatch();
  const { user } = useGlobalContext();
  const [verifyLoading, setVerifyLoading] = useState<boolean>(false);
  const [isRegistration, setIsRegistration] = useState<boolean>(false);
  const [smsRu, setSmsR] = useState<boolean>(true);
  const [isRecapcha, setIsRecapcha] = useState<boolean>(false);
  const [pN, setPN] = useState<string>("");
  const [country, setCountry] = useState("RU");

  const nav = searchParams.get("nav");

  useEffect(() => {
    if (loadingRef === "succeeded") setFlag(false);
  }, [loadingRef]);

  const tryToLogin = async () => {
    const phoneNumberToSave = pN;
    myDispatch(setUserPhoneNumber(phoneNumberToSave));
    let number = pN;
    // `+7${phoneNumber.value}`;
    console.log("number", number);

    setError("");
    if (number === "" || number === undefined)
      return setError("Please enter a valid phone number!");

    if (alternativeAuth) {
      myDispatch(setUserPhoneNumber(phoneNumberToSave));
      number = number.slice(1);

      if (isCall) {
        myDispatch(setCall(false));
        myDispatch(fetchAuthCall(number));
      } else myDispatch(fetchAuthSms(number));
    } else {
      try {
        setIsRecapcha(true);
        const response = await setUpRecaptcha(number);
        setResult(response);
        setFlag(true);
      } catch (err: any) {
        console.log(number);
        console.log("err.message !!!", err.message);
        setError(firebaseError(err.message));
        setIsRecapcha(false);
        if (
          firebaseError(err.message) ===
          "Ошибка авторизации: неизвестная ошибка, отправьте запрос еще раз!"
        ) {
          myDispatch(setAlternativeAuth(true));
          // @ts-ignore
          verifyRef.current.innerHTML = "";
        } else myDispatch(setAlternativeAuth(true));
      }
    }
  };

  const verifyOtp = async (otp: string) => {
    setError("");
    if (otp === "" || otp === null) return;
    if (alternativeAuth) {
      myDispatch(fetchAuthVerify({ code: otp, navigate }));
    } else {
      try {
        setVerifyLoading(true);
        await result.confirm(otp);
      } catch (err: any) {
        setVerifyLoading(false);
        setError(firebaseError(err.message));
      }
    }
  };

  useEffect(() => {
    if (user && user.uid)
      myDispatch(getRefIDFromFireBase({ uid: user.uid, navigate }));
    // eslint-disable-next-line react-hooks/exhaustive-deps

    const phone = localStorage.getItem("phoneNumberInLogin");
    phone && setPN(phone);
    localStorage.removeItem("phoneNumberInLogin");
  }, []);

  useMemo(() => {
    if (fireBaseData === "") setIsRegistration(true);
    else if (fireBaseData === null) {
      if (params.referralId) navigate(`/login/${params.referralId}`);
      else navigate(`/login`);
    } else navigate("/profile");
    setVerifyLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fireBaseData]);

  const verifyRef = useRef();

  useEffect(() => {
    if (loadingAltAuth === "succeeded") setFlag(true);
  }, [loadingAltAuth]);

  useEffect(() => {
    if (altAuth && altAuth.act === "registration") setIsRegistration(true);
  }, [altAuth]);

  const setCallHandler = () => {
    myDispatch(setCall(true));
  };

  return (
    <Wrapper>
      {/* <div className="row1"></div>
      <div className="row2"> */}
        <PageContainer
          title={flag ? "Подтверждение" : "Вход и регистрация"}
          goBack={
            nav === "code"
              ? () => {
                  searchParams.delete("nav");
                  setSearchParams(searchParams);
                  window.location.reload();
                }
              : null
          }
        >
          <LoginWrapper>
            <FormWrapper>
              {isRegistration ? (
                <RegistrationPage
                  referralId={params.referralId}
                  code={altAuth?.code}
                  setDisable={setDisable}
                />
              ) : !flag ? (
                <LoginForm
                  pN={pN}
                  setPN={setPN}
                  tryToLogin={tryToLogin}
                  error={error}
                  verifyRef={verifyRef}
                  flag={flag}
                  smsRu={alternativeAuth}
                  isRecapcha={isRecapcha}
                  setIsRecapcha={setIsRecapcha}
                  country={country}
                  setCountry={setCountry}
                />
              ) : (
                <VerifyCodeForm
                  // phoneNumber={phoneNumber}
                  phoneNumber={pN}
                  setOtp={setOtp}
                  verifyOtp={verifyOtp}
                  error={error}
                  verifyLoading={verifyLoading}
                  smsRu={alternativeAuth}
                  setFlag={setFlag}
                  tryToLogin={tryToLogin}
                  setCallHandler={setCallHandler}
                />
              )}
            </FormWrapper>
          </LoginWrapper>
        </PageContainer>
      {/* </div> */}
      {/* <div className="row3"> */}
        {/* {isRegistration ? <RegistrationHint /> : <LoginHint />} */}
      {/* </div> */}
    </Wrapper>
  );
};

export default memo(LoginPage);



// import React, { useState } from "react";
// import PageContainer from "../../components/pageContainer";
// import { FormWrapper, LoginWrapper } from "./styles";
// import LoginForm from "../../components/loginForm";

// const LoginPage: React.FC = () => {
//   const [error, setError] = useState<string | null>(null);

//   return (
//     <PageContainer title={"Вход и регистрация"}>
//       <LoginWrapper>
//         <FormWrapper>
//           <LoginForm error={error} />
//         </FormWrapper>
//       </LoginWrapper>
//     </PageContainer>
//   );
// };

// export default LoginPage;
