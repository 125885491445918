import axios from "axios";
import { BASE_URL } from "../constants/general";

interface Props {
  method?: "GET" | "POST" | "PUT" | "DELETE";
  data?: any;
  path?: string;
  url?: string;
  params?: object;
  headers?: object;
}

const getToken = () => {
  return localStorage.getItem('accessToken');
}

export const httpRequest = ({ method, data, path, url,params, headers }: Props) => {
  
  return axios({
    method,
    data,
    url: url || `${BASE_URL}/${path}`,
    params,
    headers: {
      ...headers,
      // Authorization: `Bearer ${getToken()}`,
      Authorization: `Bearer ${getToken()}`
      // Authorization: "Basic YWRtaW46UnVra2k2NjY=",
    },
  });
};
