import { Container } from "./../../components/styles/styles";
import styled from "styled-components";

export const PageWrapper = styled(Container)`
  min-height: calc(100vh - 240px);
  padding: 40px 0;
  display: flex;
  @media only screen and (max-width: 1050px) {
    padding: 20px 0;
  }
  @media only screen and (max-width: 576px) {
    padding: 0;
    min-height: 100vh;
  }
`;
