import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {httpRequest} from '../../../utils/httpRequest';

type Loading = 'idle' | 'pending' | 'succeeded' | 'failed';

export const getMe = createAsyncThunk(
  'auth/getMe',
  async (refId: string) => {
      const response = await httpRequest({
        method: "GET",
        path: `customers/${refId}`,
      });

      return response.data;
  }
);

export const fetchRegistration = createAsyncThunk(
  'auth/fetchRegistration',
  async ({data, navigate}:{ data: any, navigate: any}) => {
    try {

      const response = await httpRequest({
        path: "customers/create",
        method: "POST",
        data: data,
      }) as any;

      localStorage.setItem('accessToken', response.data.token )
      navigate('/profile');
      return response.data;
    } catch (err) {
      console.log('Registration Error');
    }
  }
);

export const getRefIDFromFireBase = createAsyncThunk(
  'auth/getRefIDFromFireBase',
  async ({uid, navigate}:{uid: string | null, navigate?: any}, thunkApi: any) => {
      const response = await httpRequest({
            method: "GET",
            path: "customers/exist/by-uid",
            params: { uid },
          });
      if (response.data !== "") {
        await thunkApi.dispatch(getMe(response.data.refId)) 
      } else {
        // navigate('/registration')
      }
      return response.data;
  }
);

// export const getRefIDFromFireBase = createAsyncThunk(
//   'auth/getRefIDFromFireBase',
//   async ({uid, navigate}:{uid: string | null, navigate?: any}, thunkApi: any) => {
//       const response = await httpRequest({
//             method: "GET",
//             path: "customers/exist/by-uid",
//             params: { uid },
//           });

//           console.log('response', response);
//       if (response.data !== "") {
//         await thunkApi.dispatch(getMe(response.data.refId)) 
//       } else {
//         // navigate('/registration')
//       }
//       return response.data;
//   }
// );

export const getData = createAsyncThunk(
  'auth/getData',
  async (uid: string | null, thunkApi: any) => {
      const { dispatch } = thunkApi;
      await dispatch(getRefIDFromFireBase({uid}));
  }
);

// SMS RU START
  
export const fetchAuthCall = createAsyncThunk(
  'auth/fetchAuthCall',
  async (phoneNumber: string) => {
      const response = await httpRequest({
        method: "POST",
        path: `sms-auth/call?phone=${phoneNumber}`,
      });
      
      return response.data;
  }
);

export const fetchAuthSms = createAsyncThunk(
  'auth/fetchAuthSms',
  async (phoneNumber: string, thunkApi:any) => {
      const response = await httpRequest({
        method: "POST",
        path: `sms-auth/sms?phone=${phoneNumber}`,
      });
      
      // thunkApi.dispatch(setUserPhoneNumber(phoneNumber))
      return {data: response.data, phoneNumber};
  }
);

export const fetchAuthVerify = createAsyncThunk(
  'auth/fetchAuthVerify',
  async ({code, navigate}:{code: string, navigate: any}, thunkApi:any) => {
      const response = await httpRequest({
        method: "POST",
        path: `sms-auth/verify?code=${code}`,
      });

      if (response) {
        navigate('/profile');
        if (response.data.token !== null)
        localStorage.setItem('accessToken', response.data.token)
        await thunkApi.dispatch(getRefIDFromFireBase({uid: response.data.token, navigate}))
      }
      
      return response.data;
  }
);

export const fetchAlternativeLogout = createAsyncThunk(
  'auth/fetchAlternativeLogout',
  async ({phoneNumber, navigate}:{phoneNumber: string, navigate: any}, thunkApi:any) => {
      const response = await httpRequest({
        method: "POST",
        path: `sms-auth/logout?phone=${phoneNumber}`,
      });

      localStorage.removeItem('accessToken');
      navigate('/login');
      
      return response.data;
  }
);

export const fetchAuthLogout = createAsyncThunk(
  'auth/fetchAuthLogout',
  async (phoneNumber: string) => {
      const response = await httpRequest({
        method: "POST",
        path: `sms-auth/logout?phone=${phoneNumber}`,
      });
      
      return response.data;
  }
);
// SMS RU END

interface TVehicle {
  user: any;
  fireBaseData: any;
  loading: Loading;
  error: string | null;
  loadingReg: Loading;
  loadingRef: Loading;
  altAuth: any | null;
  userId: any | null;
  loadingAltAuth: Loading;
  phoneNumber: string | null;
  alternativeAuth: boolean;
  isCall: true;
  verifyFlag: boolean;
}

const initialState: TVehicle = {
  user: null,
  fireBaseData: null,
  loading: 'idle',
  error: null,
  loadingReg: 'idle',
  loadingRef: 'pending',
  altAuth: null,
  userId: null,
  loadingAltAuth: 'idle',
  phoneNumber: null,
  alternativeAuth: false,
  isCall: true,
  verifyFlag: false,
};

// auth store

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuth(state) {
      state.user = null;
      state.fireBaseData = null;
      state.loading = 'idle';
      state.error = null;
      state.loadingReg = 'idle';
      state.loadingRef = 'pending';
      state.altAuth = null;
      state.userId = null;
      state.loadingAltAuth = 'idle';
      state.phoneNumber = null;
      state.alternativeAuth = false;
      state.isCall = true;
      state.verifyFlag = false;
    },
    setUserPhoneNumber(state, action){
      state.phoneNumber = action.payload;
    } ,
    setAlternativeAuth(state, action) {
      state.alternativeAuth = action.payload;
    },
    setCall(state, action ) {
      state.isCall = action.payload;
    },
    setVerifyFlag(state, action) {
      state.verifyFlag = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMe.fulfilled, (state, action) => {
      state.user = action.payload;
      state.loading = 'succeeded';
    });
    builder.addCase(getMe.pending, (state) => {
      state.error = null;
      state.loading = 'pending'
    });
    builder.addCase(getMe.rejected, (state) => {
      state.error = 'Внимание какая то ошибка';
      state.loading = 'failed';
      state.user = null;
    });
    builder.addCase(getRefIDFromFireBase.fulfilled, (state, action) => {
      state.fireBaseData = action.payload;
      state.loadingRef = 'succeeded';
    });
    builder.addCase(getRefIDFromFireBase.pending, (state) => {
      state.error = null;
      state.loadingRef = 'pending'
    });
    builder.addCase(getRefIDFromFireBase.rejected, (state) => {
      state.error = 'Внимание какая то ошибка';
      state.loadingRef = 'failed';
      state.fireBaseData = null;
    });
    builder.addCase(fetchRegistration.fulfilled, (state, action) => {
      state.error = null;
      state.loadingReg = 'succeeded';
    });
    builder.addCase(fetchRegistration.pending, (state) => {
      state.error = null;
      state.loadingReg = 'pending'
    });
    builder.addCase(fetchRegistration.rejected, (state) => {
      state.error = 'Внимание какая то ошибка';
      state.loadingReg = 'failed';
    });
    builder.addCase(fetchAuthCall.fulfilled, (state, action) => {
      state.error = null;
      state.loadingAltAuth = 'succeeded';
      state.userId = action.payload;
    });
    builder.addCase(fetchAuthCall.pending, (state) => {
      state.error = null;
      state.loadingAltAuth = 'pending'
    });
    builder.addCase(fetchAuthCall.rejected, (state) => {
      state.error = 'Внимание какая то ошибка';
      state.loadingAltAuth = 'failed';
      state.userId = null;
    });
    builder.addCase(fetchAuthSms.fulfilled, (state, action) => {
      state.error = null;
      state.loadingAltAuth = 'succeeded';
      state.userId = action.payload.data;
      state.phoneNumber = action.payload.phoneNumber;
    });
    builder.addCase(fetchAuthSms.pending, (state) => {
      state.error = null;
      state.loadingAltAuth = 'pending'
    });
    builder.addCase(fetchAuthSms.rejected, (state) => {
      state.error = 'Внимание какая то ошибка';
      state.loadingAltAuth = 'failed';
      state.userId = null;
    });
    builder.addCase(fetchAuthVerify.fulfilled, (state, action) => {
      state.error = null;
      state.loadingAltAuth = 'succeeded';
      state.altAuth = action.payload;
    });
    builder.addCase(fetchAuthVerify.pending, (state) => {
      state.error = null;
      state.loadingAltAuth = 'pending'
    });
    builder.addCase(fetchAuthVerify.rejected, (state) => {
      state.error = 'Внимание какая то ошибка';
      state.loadingAltAuth = 'failed';
    });
  },
});

// uploadPassportImages
// removePassportImages

export const { clearAuth, setUserPhoneNumber, setAlternativeAuth, setCall, setVerifyFlag } = authSlice.actions;

export default authSlice.reducer;








// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { httpRequest } from '../../../utils/httpRequest';

// type Loading = 'idle' | 'pending' | 'succeeded' | 'failed';

// interface TVehicle {
//   user: any;
//   fireBaseData: any;
//   loading: Loading;
//   error: string | null;
//   loadingReg: Loading;
// }

// const initialState: TVehicle = {
//   user: null,
//   fireBaseData: null,
//   loading: 'pending',
//   error: null,
//   loadingReg: 'pending'
// };

// export const getMe = createAsyncThunk(
//   'auth/getMe',
//   async (refId: string) => {
//       const response = await httpRequest({
//         method: "GET",
//         path: `customers/${refId}`,
//       });
      
//       return response.data;
//   }
// );


// export const fetchRegistration = createAsyncThunk(
//   'auth/fetchRegistration',
//   async ({data, navigate}:{ data: any, navigate: any}) => {
//     try {

//       const response = await httpRequest({
//         path: "customers/create",
//         method: "POST",
//         data: data,
//       });

//       navigate('/users');
//       return response.data;
//     } catch (err) {
//       console.log('Registration Error');
//     }
//   }
// );

// export const getRefIDFromFireBase = createAsyncThunk(
//   'auth/getRefIDFromFireBase',
//   async ({uid, navigate}:{uid: string, navigate?: any}, thunkApi: any) => {
//       const response = await httpRequest({
//             method: "GET",
//             path: "customers/exist/by-uid",
//             params: { uid },
//           });

//       if (response.data !== "") {
//         await thunkApi.dispatch(getMe(response.data.refId)) 
//       } else {
//         navigate('/registration')
//       }
//       return response.data;
//   }
// );

// export const getData = createAsyncThunk(
//   'auth/getData',
//   async (uid: string, thunkApi: any) => {
//       const { dispatch } = thunkApi;
//       await dispatch(getRefIDFromFireBase({uid}));
//   }
// );

// export const authSlice = createSlice({
//   name: 'auth',
//   initialState,
//   reducers: {
//     clearAuth(state) {
//       state.user = null;
//       state.fireBaseData = null;
//       state.loading = 'pending';
//       state.error = null;
//     },
//     setAuth(state) {
//       state.user = { name: "Иван"}
//     }
//   },
//   extraReducers: (builder) => {
//     builder.addCase(getMe.fulfilled, (state, action) => {
//       state.user = action.payload;
//       state.loading = 'succeeded';
//     });
//     builder.addCase(getMe.pending, (state) => {
//       state.error = null;
//       state.loading = 'pending'
//     });
//     builder.addCase(getMe.rejected, (state) => {
//       state.error = 'Внимание какая то ошибка';
//       state.loading = 'failed';
//       state.user = null;
//     });
//     builder.addCase(getRefIDFromFireBase.fulfilled, (state, action) => {
//       state.fireBaseData = action.payload;
//       state.loading = 'succeeded';
//     });
//     builder.addCase(getRefIDFromFireBase.pending, (state) => {
//       state.error = null;
//       state.loading = 'pending'
//     });
//     builder.addCase(getRefIDFromFireBase.rejected, (state) => {
//       state.error = 'Внимание какая то ошибка';
//       state.loading = 'failed';
//       state.fireBaseData = null;
//     });
//     builder.addCase(fetchRegistration.fulfilled, (state, action) => {
//       state.error = null;
//       state.loadingReg = 'succeeded';
//     });
//     builder.addCase(fetchRegistration.pending, (state) => {
//       state.error = null;
//       state.loadingReg = 'pending'
//     });
//     builder.addCase(fetchRegistration.rejected, (state) => {
//       state.error = 'Внимание какая то ошибка';
//       state.loadingReg = 'failed';
//     });
//   },
// });

// // export const { clearAuth, setAuth } = authSlice.actions;
// export const { clearAuth, setAuth,setUserPhoneNumber, setAlternativeAuth, setCall, setVerifyFlag } = authSlice.actions;

// export default authSlice.reducer;