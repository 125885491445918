import { Form } from "antd";
import React, { memo, useEffect, useState } from "react";
import { InputBox } from "../../components/form";
import { Button } from "../../components/form/styles";
import PageContainer from "../../components/pageContainer";
import { LoginWrapper } from "../loginPage/styles";

import {
  FormWrapper,
  InputWrapper,
  PhoneNumberBox,
  UserTypeBox,
  CityList
} from "./styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGlobalContext } from "../../App";
import { fetchRegistration } from "../../redux/store/reducers/authSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
// import { CityList } from "../profilePage/styles";
// import {
//   formatPhoneNumberIntl,
//   parsePhoneNumber,
// } from "react-phone-number-input";

import countries from "../../constants/world.json";
import cities from "../../constants/city.json";
import { formatPhoneNumberIntl, parsePhoneNumber } from "react-phone-number-input";

interface Props {
  // phone?: any;
  referralId?: string;
  code?: string;
  setDisable?: any;
}

interface Errors {
  [x: string]: boolean;
}

const RegistrationPage: React.FC<Props> = ({
  referralId,
  code,
  // phone
  setDisable,
}) => {
  const navigate = useNavigate();
  const { user } = useGlobalContext();
  const myDispatch = useAppDispatch();
  const [errValid, setErrValid] = useState<Errors>({});
  const phoneNumberAlt = useAppSelector(
    (state: RootState) => state.auth.phoneNumber
  );

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [role, setRole] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [city, setCity] = useState({ text: "", open: false });
  const [country, setCountry] = useState<any>({ text: "", open: false });

  // const [options, setOptions] = useState<any>(russianCities);
  const [options, setOptions] = useState<any>(
    cities.filter(({ country_id }) => country_id === country?.data?.country_id)
  );
  const [countryOptions, setCountryOptions] = useState<any>(
    countries.filter(({ country_id }) => country_id)
  );

  // const [country, setCountry] = useState<any>("");

  const onChange = (e: any) => {
    const c_id = country?.data?.country_id;

    setCity({ text: e.target.value, open: true });

    // const exist = russianCities.find(
    const exist = cities.find(
      ({ name, country_id }) =>
        country_id === c_id &&
        name.toLowerCase() === e.target.value.toLowerCase()
    );

    if (exist) {
      setCity({ text: exist.name, open: false });

      // setUser({
      //   ...userState,
      //   city: exist.name,
      // });
    } else {
      setOptions(
        // russianCities.filter(({ name }: any) =>
        cities.filter(
          ({ name, country_id }: any) =>
            country_id === c_id &&
            (e.target.value
              ? name.toLowerCase().includes(e.target.value.toLowerCase())
              : true)
        )
      );
      // setUser({
      //   ...userState,
      //   city: "",
      // });
    }
  };

  const onChangeCountry = (e: any) => {
    setCountry({ text: e.target.value, open: true });

    // const exist = russianCities.find(
    const exist = countries.find(
      ({ name }) => name.toLowerCase() === e.target.value.toLowerCase()
    );

    if (exist) {
      setCountry({ text: exist.name, open: false, data: exist });

      // setUser({
      //   ...userState,
      //   city: exist.name,
      // });
    } else {
      setCountryOptions(
        countries.filter(
          ({ name, country_id }: any) =>
            country_id &&
            (e.target.value
              ? name.toLowerCase().includes(e.target.value.toLowerCase())
              : true)
        )
      );
      setCity({ text: "", open: false });
      // setUser({
      //   ...userState,
      //   city: "",
      // });
    }
  };

  const onSelect = (e: any) => {
    // setUser({
    //   ...userState,
    //   city: e,
    // });
    setCity({ text: e, open: false });
  };

  const onSelectCountry = (e: any) => {
    // setUser({
    //   ...userState,
    //   city: e,
    // });
    setCountry({ text: e.name, open: false, data: e });
  };

  useEffect(() => {
    const parsePN = parsePhoneNumber(phoneNumber);
    const countryFromPN: any = countries.find(
      ({ alpha2, country_id }) =>
        country_id && alpha2 === parsePN?.country?.toLowerCase()
    );
    setCountry({
      text: countryFromPN?.name,
      open: false,
      data: countryFromPN,
    });
  }, [phoneNumber]);

  useEffect(() => {
    if (country.text === "Россия" && !Boolean(city)) {
      setCity({ text: "Москва", open: false });
    }
  }, [country]);

  // useEffect(() => {
  //   if (Boolean(role)) {
  //     setDisable(true);
  //   } else {
  //     setDisable(false);
  //   }
  // }, [role]);

  useEffect(() => {
    if (user && user.phoneNumber) setPhoneNumber(user.phoneNumber);
  }, [user]);

  useEffect(() => {
    if (phoneNumberAlt) setPhoneNumber(`${phoneNumberAlt}`);
  }, [phoneNumberAlt]);

  // useEffect(() => {
  //   console.log(phone);
  //   setPhoneNumber(phone);
  // }, []);
  // console.log("register", phone);

  const registerUser = () => {
    if (
      firstName &&
      lastName &&
      // && middleName
      // role &&
      city?.text &&
      country?.data?.country_id
    ) {
      let data: any = {
        referralId: referralId,
        customer: {
          firstName: firstName,
          secondName: lastName,
          phoneNumber: phoneNumber,
          // role: role,
          city: city?.text,
          country: country?.data?.name,
        },
      };
      if (code) data.customer.code = code;

      myDispatch(fetchRegistration({ data, navigate }));
      // makeRegisterRequest();
    }
  };

  const validation = (text: string, name: string) => {
    const result = /^[а-яА-Я]+$/.test(text);
    setErrValid((prevState: any) => ({ ...prevState, [name]: result }));
  };

  useEffect(() => {
    const c_id = country?.data?.country_id;
    if (c_id) {
      // console.log(
      //   "Country: ",
      //   country,
      //   cities.filter(({ country_id }) => country_id === c_id)
      // );

      setOptions(cities.filter(({ country_id }) => country_id === c_id));
    }
  }, [country?.data?.country_id]);

  return (
    <PageContainer
      title="Вход и регистрация"
      // goBack={
      //   nav === "code"
      //     ? () => {
      //         searchParams.delete("nav");
      //         setSearchParams(searchParams);
      //         window.location.reload();
      //       }
      //     : null
      // }
    >
      <LoginWrapper>
        <FormWrapper>
          <Form layout="vertical">
            <InputWrapper>
              <InputBox
                style={{ color: errValid.lastName ? "inherit" : "red" }}
                label="Фамилия"
                placeholder="Иванов"
                value={lastName}
                name="lastName"
                onChange={(e: any) => {
                  setLastName(e.target.value);
                  validation(e.target.value, "lastName");
                }}
              />
              {!errValid.lastName && (
                <div style={{ color: "red", marginTop: "-20px" }}>
                  {lastName.length > 0
                    ? 'Могут быть только буквы от "а" до "я"'
                    : "Обязательное поле"}
                </div>
              )}
            </InputWrapper>

            <InputWrapper>
              <InputBox
                style={{ color: errValid.firstName ? "inherit" : "red" }}
                label="Имя"
                placeholder="Иван"
                name="firstName"
                value={firstName}
                onChange={(e: any) => {
                  setFirstName(e.target.value);
                  validation(e.target.value, "firstName");
                }}
              />
              {!errValid.firstName && (
                <div style={{ color: "red", marginTop: "-20px" }}>
                  {firstName.length > 0
                    ? 'Могут быть только буквы от "а" до "я"'
                    : "Обязательное поле"}
                </div>
              )}
            </InputWrapper>

            {/* <InputWrapper>
          <InputBox
            label="Отчество"
            placeholder="Иванович"
            value={middleName}
            onChange={(e: any) => setMiddleName(e.target.value)}
          />
        </InputWrapper> */}

            <InputWrapper>
              <InputBox
                // disabled
                // type="tel"
                label="Номер телефона"
                // format={"+7 (###) ###-##-##"}
                // placeholder="+7"
                // value={authentication.currentUser?.phoneNumber}
                // defaultValue={phoneNumber || ""}
              >
                <PhoneNumberBox>
                  {/* {displayPhoneNumber(phoneNumber)} */}
                  {formatPhoneNumberIntl(phoneNumber)}
                </PhoneNumberBox>
              </InputBox>
            </InputWrapper>
            <InputWrapper>
              <InputBox required label={"Страна"} disabled={updateLoading}>
                <div
                  tabIndex={0}
                  style={{ border: 0, width: "100%" }}
                  onBlur={() =>
                    setCountry((prev: any) => ({ ...prev, open: false }))
                  }
                  onFocus={() => {
                    setCountry((prev: any) => ({ ...prev, open: true }));
                    setCountryOptions(
                      countries.filter(
                        ({ name, country_id }: any) =>
                          country_id &&
                          (country.text ? name.includes(country.text) : true)
                      )
                    );
                  }}
                >
                  <input
                    className="name"
                    type="text"
                    placeholder="Россия"
                    value={country.text || ""}
                    onChange={onChangeCountry}
                    // ref={cityRef}
                    onClick={() => {
                      if (!country.open) {
                        setCountry((prev: any) => ({ ...prev, open: true }));
                        setCountryOptions(
                          countries.filter(
                            ({ name, country_id }: any) =>
                              country_id &&
                              (country.text
                                ? name
                                    .toLowerCase()
                                    .includes(country.text.toLowerCase())
                                : true)
                          )
                        );
                      }
                    }}
                  />
                  {country.open && countryOptions.length > 0 && (
                    <CityList>
                      {countryOptions.length > 0 &&
                        countryOptions.slice(0, 5).map(
                          (
                            {
                              // coords,
                              // district,
                              // name,
                              // population,
                              // subject,

                              id,
                              alpha2,
                              alpha3,
                              name,
                              country_id,
                            }: any,
                            index: any
                          ) => (
                            <div
                              key={`city${index}`}
                              className="item"
                              onMouseDown={(e) => {
                                onSelectCountry({
                                  id,
                                  alpha2,
                                  alpha3,
                                  name,
                                  country_id,
                                });
                              }}
                            >
                              <b>{name}</b>
                              {/* {district} */}
                            </div>
                          )
                        )}
                    </CityList>
                  )}
                </div>
              </InputBox>
            </InputWrapper>
            <InputWrapper>
              <InputBox required label={"Город"} disabled={updateLoading}>
                <div
                  tabIndex={0}
                  style={{ border: 0, width: "100%" }}
                  onBlur={() =>
                    setCity((prev: any) => ({ ...prev, open: false }))
                  }
                  onFocus={() => {
                    setCity((prev: any) => ({ ...prev, open: true }));
                    setOptions(
                      // russianCities.filter(({ name }: any) =>
                      cities.filter(
                        ({ name, country_id }: any) =>
                          country_id === country?.data?.country_id &&
                          (city.text ? name.includes(city.text) : true)
                      )
                    );
                  }}
                >
                  {/* <div
                className="name"
                // type="text"
                // placeholder="Москва"
                // value={city.text}
                // onChange={onChange}
                // ref={cityRef}
              >
                {user.city || <span className="placeholder">Москва</span>}
              </div> */}
                  <input
                    className="name"
                    type="text"
                    placeholder="Москва"
                    value={city.text || ""}
                    onChange={onChange}
                    // ref={cityRef}
                    disabled={!country?.data?.country_id}
                    onClick={() => {
                      if (!city.open) {
                        setCity((prev: any) => ({ ...prev, open: true }));
                        setOptions(
                          // russianCities.filter(({ name }: any) =>
                          cities.filter(({ name, country_id }: any) =>
                            country_id === country?.data?.country_id &&
                            city.text
                              ? name
                                  .toLowerCase()
                                  .includes(city.text.toLowerCase())
                              : true
                          )
                        );
                      }
                    }}
                  />
                  {city.open && options.length > 0 && (
                    <CityList>
                      {/* {options.length === 0 && (
                  <div className="not-found">NOT FOUND</div>
                )} */}
                      {options.length > 0 &&
                        options
                          .slice(0, 5)
                          .map(
                            (
                              {
                                coords,
                                district,
                                name,
                                population,
                                subject,
                                region,
                                country,
                              }: any,
                              index: any
                            ) => (
                              <div
                                key={`city${index}`}
                                className="item"
                                onMouseDown={(e) => {
                                  onSelect(name);
                                }}
                              >
                                <b>{name},</b>
                                {region}
                                {/* , {country} */}
                              </div>
                            )
                          )}
                    </CityList>
                  )}
                </div>
              </InputBox>
            </InputWrapper>
            {/* <InputWrapper>
              <InputBox label="Роль">
                <UserTypeBox>
                  <div
                    onClick={() => setRole("worker")}
                    className={`box ${role === "worker" && "active"}`}
                  >
                    Исполнитель
                  </div>
                  <div className="line"></div>
                  <div
                    onClick={() => setRole("customer")}
                    className={`box ${role === "customer" && "active"}`}
                  >
                    Заказчик
                  </div>
                  <div className="line"></div> 
                  <div
                    onClick={() => setRole("agent")}
                    className={`box ${role === "agent" && "active"}`}
                  >
                    Агент
                  </div> 
                </UserTypeBox>
              </InputBox>
            </InputWrapper> */}
            {/* <Form.Item label="Тип техники">
              <Input placeholder="бульдозер" />
            </Form.Item> */}
            <Button
              onClick={registerUser}
              fit
              disabled={
                !(
                  errValid.firstName &&
                  errValid.lastName &&
                  // role &&
                  phoneNumber &&
                  country?.data?.country_id &&
                  city?.text
                )
              }
            >
              Продолжить
            </Button>
          </Form>
        </FormWrapper>
      </LoginWrapper>
    </PageContainer>
  );
};

export default memo(RegistrationPage);





// import { Form } from "antd";
// import React, { useEffect, useState } from "react";
// import { InputBox } from "../../components/form";
// import { Button } from "../../components/form/styles";
// import PageContainer from "../../components/pageContainer";
// import { LoginWrapper } from "../loginPage/styles";

// import {
//   FormWrapper,
//   InputWrapper,
//   PhoneNumberBox,
//   UserTypeBox,
// } from "./styles";
// import { useNavigate, useSearchParams } from "react-router-dom";
// import { displayPhoneNumber } from "../../utils/numbers";
// import { useGlobalContext } from "../../App";
// import { fetchRegistration } from "../../redux/store/reducers/authSlice";
// import { useAppDispatch } from "../../hooks";

// interface Props {
//   phoneNumber?: any;
// }

// const RegistrationPage: React.FC<Props> = () => {
//   const navigate = useNavigate();
//   const { user } = useGlobalContext();
//   const myDispatch = useAppDispatch();

//   const [firstName, setFirstName] = useState("");
//   const [lastName, setLastName] = useState("");
//   const [role, setRole] = useState("");
//   const [phoneNumber, setPhoneNumber] = useState<string>("");
//   const [searchParams] = useSearchParams();

//   useEffect(() => {
//     if (user && user.phoneNumber) setPhoneNumber(user.phoneNumber);
//   }, [user]);

//   const registerUser = () => {
//     if (firstName && lastName && role) {
//       const data = {
//         referralId: searchParams.get("referralId"),
//         customer: {
//           firstName: firstName,
//           secondName: lastName,
//           phoneNumber: phoneNumber,
//           role: role,
//         },
//       };

//       myDispatch(fetchRegistration({ data, navigate }));
//     }
//   };

//   return (
//     <PageContainer title="Вход и регистрация">
//       <LoginWrapper>
//         <FormWrapper>
//           <Form layout="vertical">
//             <InputWrapper>
//               <InputBox
//                 label="Фамилия"
//                 placeholder="Иванов"
//                 value={lastName}
//                 onChange={(e: any) => setLastName(e.target.value)}
//               />
//             </InputWrapper>

//             <InputWrapper>
//               <InputBox
//                 label="Имя"
//                 placeholder="Иван"
//                 value={firstName}
//                 onChange={(e: any) => setFirstName(e.target.value)}
//               />
//             </InputWrapper>

//             <InputWrapper>
//               <InputBox label="Номер телефона" defaultValue={phoneNumber || ""}>
//                 <PhoneNumberBox>
//                   {displayPhoneNumber(phoneNumber)}
//                 </PhoneNumberBox>
//               </InputBox>
//             </InputWrapper>
//             <InputWrapper>
//               <InputBox label="Роль">
//                 <UserTypeBox>
//                   <div
//                     onClick={() => setRole("worker")}
//                     className={`box ${role === "worker" && "active"}`}
//                   >
//                     Исполнитель
//                   </div>
//                   <div className="line"></div>
//                   <div
//                     onClick={() => setRole("agent")}
//                     className={`box ${role === "agent" && "active"}`}
//                   >
//                     Агент
//                   </div>
//                 </UserTypeBox>
//               </InputBox>
//             </InputWrapper>
//             <Button
//               onClick={registerUser}
//               fit
//               disabled={!(firstName && lastName && role)}
//             >
//               Продолжить
//             </Button>
//           </Form>
//         </FormWrapper>
//       </LoginWrapper>
//     </PageContainer>
//   );
// };

// export default RegistrationPage;
