import {Col, Form, Input, Row, Select, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {RootState} from "../../../../../redux/store/store";
import {HeaderGroup} from "./styles";
import {updateUserData} from "../../../../../redux/store/reducers/referalsSlice";
import "react-phone-input-2/lib/style.css";
import UserInfoFormRight from "./UserInfoFormRight";
import moment from "moment";
import UserInfoFormDocs from "./UserInfoFormDocs";
import UserInfoFormBankData from "./UserInfoFormBankData";
import {getVehicleCategories,} from "../../../../../redux/store/reducers/vehicleSlice";

interface TInfo {
  title: string;
  value: string | number;
  titleKey: string;
}

interface Props {
  info: TInfo[];
  currentUser: any;
}

interface Vehicle {
  categoryId: number;
  model: string;
  id: number;
  stateNumber: string;
  brand: string;
}

interface VehPhoto {
  id: number;
  ownerId: number;
  vehicleId: number;
  photo: string;
}

const UserInfoForm: React.FC<Props> = ({info, currentUser}) => {
  const [formData, setFormData] = useState<any>({});
  const [update, setUpdate] = useState<any>({});
  const dispatch = useAppDispatch();
  const [vehicle, setVehicle] = useState<string | null>(null);
  const [vehPhoto, setVehPhoto] = useState<VehPhoto[] | []>([]);
  const {loading, parrentUser} = useAppSelector(
      (state: RootState) => state.referals
  );
  const [form] = Form.useForm();
  const {Option} = Select;

  useEffect(() => {
    dispatch(getVehicleCategories());
  }, []);

  useEffect(() => {
    if (
        currentUser &&
        currentUser.customer &&
        currentUser.customer.vehicles &&
        currentUser.customer.vehicles.length > 0
    ) {
      const filteredVehicle = currentUser.customer.vehicles.find(
          (item: any) => {
            if (vehicle !== null && parseInt(item.id) === parseInt(vehicle))
              return item;
            return false;
          }
      );
      if (filteredVehicle) {
        form.setFieldsValue({
          vehCatId: filteredVehicle.categoryId,
          vehBrand: filteredVehicle.brand,
          vehModel: filteredVehicle.model,
          vehStateNumber: filteredVehicle.stateNumber,
        });
        setVehPhoto(filteredVehicle.photos);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle]);

  useEffect(() => {
    if (currentUser && currentUser.customer) {
      if (
          currentUser &&
          currentUser.customer &&
          currentUser.customer.vehicles &&
          currentUser.customer.vehicles.length > 0
      ) {
        setVehicle(currentUser.customer.vehicles[0].id);
      }
      setFormData({
        ...currentUser.customer,
        birthday: moment(currentUser.customer.birthday, "YYYY/MM/DD"),
        totalPaid: currentUser.reward.total,
        transactionsCount: currentUser.reward.transactionsCount,
      });
      form.setFieldsValue({
        ...currentUser.customer,
        createdAt: moment(currentUser.customer.createdAt).format("DD.MM.YYYY"),
        birthday:
            currentUser.customer.birthday !== null
                ? moment(currentUser.customer.birthday, "YYYY/MM/DD")
                : moment(),
        totalPaid: currentUser.reward.total,
        transactionsCount: currentUser.reward.transactionsCount,
        vehCatId:
            currentUser?.customer?.vehicles?.length > 0
                ? currentUser.customer.vehicles[0].categoryId
                : "",
        vehBrand:
            currentUser?.customer?.vehicles?.length > 0
                ? currentUser.customer.vehicles[0].brand
                : "",
        vehModel:
            currentUser?.customer?.vehicles?.length > 0
                ? currentUser.customer.vehicles[0].model
                : "",
        vehStateNumber:
            currentUser?.customer?.vehicles?.length > 0
                ? currentUser.customer.vehicles[0].stateNumber
                : "",
        inn: currentUser?.customer?.requisites
            ? currentUser.customer.requisites.inn
            : "",
        bik: currentUser?.customer?.requisites
            ? currentUser.customer.requisites.bik
            : "",
        rs: currentUser?.customer?.requisites
            ? currentUser.customer.requisites.rs
            : "",
        ks: currentUser?.customer?.requisites
            ? currentUser.customer.requisites.rs
            : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const setVehicleHandler = (value: string) => {
    setVehicle(value);
  };

  // console.log("form ==>", form.getFieldsValue());
  // console.log("formData ==>", formData);
  // const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setFormData((prevState: any) => ({ ...prevState, [name]: value }));
  //   setUpdate((prevState: any) => ({ ...prevState, [name]: value }));
  // };

  // const onRoleChange = (value: any) => {
  //   setFormData((prevState: any) => ({ ...prevState, role: value }));
  //   setUpdate((prevState: any) => ({ ...prevState, role: value }));
  // };

  // const onCityChange = (value: any) => {
  //   setFormData((prevState: any) => ({ ...prevState, city: value }));
  //   setUpdate((prevState: any) => ({ ...prevState, city: value }));
  // };

  // const onPhoneChange = (value: any) => {
  //   console.log("value", value);
  //   const phoneNum = `+${value}`;
  //   setFormData((prevState: any) => ({ ...prevState, phoneNumber: phoneNum }));
  //   setUpdate((prevState: any) => ({ ...prevState, phoneNumber: phoneNum }));
  // };

  // const onSubmitUpdateUser = () => {
  //   console.log("submit");
  //   dispatch(updateUserData({ id: formData.id, data: update }));
  // };

  const onFinish = (values: any) => {
    const valuesForChange = {...values};

    if (values.birthday)
      valuesForChange.birthday = moment(values.birthday, "YYYY-MM-DD");
    if (vehicle) valuesForChange.vehId = vehicle;
    console.log("post", valuesForChange);
    delete valuesForChange.id;
    dispatch(updateUserData({id: values.id, data: valuesForChange}));
  };

  const onFinishFailed = (values: any) => {
    console.log("onFinishFailed", values);
  };

  // if (!currentUser) return <div>Загрузка...</div>;

  return (
      <Row gutter={[10, 10]}>
        <Col xs={24} md={12}>
          <Form
              labelCol={{span: 7}}
              wrapperCol={{span: 17, offset: 0}}
              form={form}
              labelAlign="left"
              size="large"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
          >
            <Col span={24}>
              <HeaderGroup>
                <Typography.Title level={4}>Персональные данные</Typography.Title>
              </HeaderGroup>
            </Col>
            <Form.Item name="id" label="ID">
              <Input disabled/>
            </Form.Item>
            <Form.Item
                name="refId"
                label="Промо-код"
                rules={[
                  {
                    required: true,
                    message: "Заполните это поле",
                  },
                ]}
            >
              <Input disabled placeholder="Промо-код"/>
            </Form.Item>
            <Form.Item
                name="secondName"
                label="Фамилия"
                rules={[
                  {
                    required: true,
                    message: "Заполните это поле",
                  },
                  {
                    validator: (_, value) =>
                        !value.includes(" ") ? Promise.resolve() : Promise.reject(),
                    message: "Уберите пробелы ",
                  },
                ]}
            >
              <Input type="string" placeholder="Фамилия" disabled/>
            </Form.Item>
            <Form.Item
                name="firstName"
                label="Имя"
                rules={[
                  {
                    required: true,
                    message: "Заполните это поле",
                  },
                  {
                    validator: (_, value) =>
                        !value.includes(" ") ? Promise.resolve() : Promise.reject(),
                    message: "Уберите пробелы ",
                  },
                ]}
            >
              <Input type="string" placeholder="Имя" disabled/>
            </Form.Item>
            <Form.Item
                name="patronymic"
                label="Отчество"
                rules={[
                  {
                    required: true,
                    message: "Заполните это поле",
                  },
                  {
                    validator: (_, value) =>
                        !value.includes(" ") ? Promise.resolve() : Promise.reject(),
                    message: "Уберите пробелы ",
                  },
                ]}
            >
              <Input type="string" placeholder="Отчество" disabled/>
            </Form.Item>

            <Form.Item
                name="createdAt"
                label="Дата регистрации"
                rules={[
                  {
                    required: true,
                    message: "Заполните это поле",
                  },
                  {
                    validator: (_, value) =>
                        !value.includes(" ") ? Promise.resolve() : Promise.reject(),
                    message: "Уберите пробелы ",
                  },
                ]}
            >
              <Input type="string" placeholder="Дата регистрации" disabled/>
            </Form.Item>

            <Form.Item
                name="role"
                label="Роль"
                rules={[
                  {
                    required: true,
                    message: "Заполните это поле",
                  },
                ]}
            >
              <Select placeholder="Роль" disabled>
                <Option value="worker">Исполнитель</Option>
                <Option value="agent">Агент</Option>
              </Select>
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} md={12}>
          <UserInfoFormRight
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              loading={loading}
              currentUser={currentUser}
          />
        </Col>
        <Col xs={24} md={12}>
          <UserInfoFormDocs
              form={form}
              currentUser={currentUser}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              formData={formData}
              vehicle={vehicle}
              setVehicleHandler={setVehicleHandler}
              vehPhoto={vehPhoto}
          />
        </Col>
        <Col xs={24} md={12}>
          <UserInfoFormBankData
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
          />
        </Col>
      </Row>
  );
};

export default UserInfoForm;
