import React, { useRef, useState } from "react";
import { Button, Col, Divider, Input, InputRef, Space, Typography } from "antd";
import UserHeader from "../../UserHeader";
import { useNavigate } from "react-router-dom";
import { Table } from "./styles";
import { ColumnType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { FilterConfirmProps } from "antd/es/table/interface";
import moment from "moment";

interface RefListData {
  id: number;
  lastName: string;
  secondName: string;
  description: string;
  key: number;
  transactionsCount: number;
  totalCommission: number;
}

interface Props {
  onClickShowRefList: (name: string) => void;
  refListData: RefListData[];
  role: string;
}

const UserInfoTable: React.FC<Props> = ({
  onClickShowRefList,
  refListData,
  role,
}) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: any
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex: any): ColumnType<any> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      let testRecord = record[dataIndex];
      if (testRecord === null) testRecord = "";
      return testRecord
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: "Номер",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Фамилия",
      dataIndex: "secondName",
      key: "secondName",
      ...getColumnSearchProps("secondName"),
    },
    {
      title: "Имя",
      dataIndex: "firstName",
      key: "firstName",
      ...getColumnSearchProps("firstName"),
    },
    {
      title: "Отчество",
      dataIndex: "patronymic",
      key: "patronymic",
      ...getColumnSearchProps("patronymic"),
    },
    {
      title: "Номер телефона",
      dataIndex: "phone",
      key: "phone",
      ...getColumnSearchProps("patronymic"),
    },
    {
      title: "Вид техники",
      dataIndex: "vehicle",
      key: "vehicle",
    },
    {
      title: "Количество выполненных заказов",
      dataIndex: "transactionsCount",
      key: "transactionsCount",
    },
    {
      title: "Комиссия",
      dataIndex: "totalCommission",
      key: "totalCommission",
    },
    {
      title: "Дата регистрации",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => (moment(date).format("DD.MM.YYYY").toString()),
      ...getColumnSearchProps("createdAt"),
    }
  ];

  return (
    <Col span={24}>
      <UserHeader close={() => onClickShowRefList("")} showTitle={false} />
      <Table
        pagination={{
          pageSize: 6,
        }}
        columns={columns}
        expandable={{
          expandedRowRender: (record: any) => {
            return (
              <Space split={<Divider type="vertical" />}>
                <Typography.Text>
                  Выполнено заказов: {record.transactionsCount}
                </Typography.Text>
                <Typography.Text>
                  Вознаграждение: {record.totalCommission}
                </Typography.Text>
              </Space>
            );
          },
        }}
        dataSource={refListData.map((item: any) => {
          const newItem = { ...item };
          newItem.key = item.id;
          return newItem;
        })}
        onRow={(record: any, rowIndex) => {
          return {
            onClick: (event: any) => navigate(`/users/${record.id}`),
          };
        }}
      />
    </Col>
  );
};

export default UserInfoTable;
