import styled from "styled-components";

export const Header = styled.div`
    padding-bottom: 5px;
    height: 33px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #FF6633;
`;