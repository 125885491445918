import React from "react";
import {
  AvatarBox,
  LoginBtn,
  Logo,
  NavbarContainer,
  NavbarWrapper,
} from "./styles";
import { MenuOutlined } from "@ant-design/icons";

import logo from "../../assets/images/Logo.png";
import { useNavigate } from "react-router-dom";
import { Dropdown, Menu } from "antd";

import { ReactComponent as ArrowDropDown } from "../../assets/icons/arrowDropDown.svg";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { RootState } from "../../redux/store/store";
import { clearAuth } from "../../redux/store/reducers/authSlice";
import { setSidebar } from "../../redux/store/reducers/uiSlice";

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state: RootState) => state.auth);

  const sidebarToggler = () => {
    dispatch(setSidebar());
  };

  return (
    <NavbarWrapper>
      <NavbarContainer>
        <div style={{ display: "flex", alignItems: "center" }}>
          <MenuOutlined
            style={{ marginRight: "20px", cursor: "pointer" }}
            onClick={sidebarToggler}
          />
          <Logo to={"/"}>
            <img src={logo} alt="logo" />
          </Logo>
        </div>
        {user ? (
          <AvatarBox>
            {/* <img
              src={
                user.customer.avatarUrl
                  ? `data:image/jpeg;base64, ${user.customer.avatarUrl}`
                  : defaultAvatar
              }
              alt=""
            /> */}
            <Dropdown
              placement="bottomRight"
              overlay={
                <Menu
                  // onClick={handleMenuClick}
                  items={[
                    {
                      label: "Выйти",
                      key: "1",
                      // icon: <UserOutlined />,
                      onClick: (e) => {
                        // signOut(authentication).then(() => {
                        localStorage.removeItem("accessToken");
                        dispatch(clearAuth());
                        navigate("/login");
                        // });
                      },
                    },
                  ]}
                />
              }
            >
              <div className="name">
                {user?.name}
                <ArrowDropDown />
              </div>
            </Dropdown>
          </AvatarBox>
        ) : (
          <LoginBtn onClick={() => navigate("/login")}>Войти</LoginBtn>
        )}
      </NavbarContainer>
    </NavbarWrapper>
  );
};

export default Navbar;
