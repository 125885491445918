import styled from "styled-components";

export const styles = {
  mainColor: "#FF6633",
  red: "rgba(255, 51, 88, 1)",
};

export const Container = styled.div`
  width: 1200px;
  margin: auto;
  @media only screen and (max-width: 1250px) {
    width: 1000px;
  }
  @media only screen and (max-width: 1050px) {
    width: 700px;
  }
  @media only screen and (max-width: 720px) {
    width: 95%;
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`;

export const SmallText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

export const Highlighted = styled.span`
  color: ${styles.mainColor};
  cursor: pointer;
`;

export const ContentTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const ContentText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

export const Anchor = styled.a`
  color: ${styles.mainColor};
  &:hover {
    color: ${styles.mainColor}aa;
  }
`;

export const MobileGoBack = styled.div`
  margin-right: 16px;
  display: none;
  @media only screen and (max-width:576px) {
    display: flex;
  }
`;
