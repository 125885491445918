import { combineReducers } from "redux";
import authSlice from "./reducers/authSlice";
import referalsSlice from "./reducers/referalsSlice";
import uiSlice from './reducers/uiSlice';
import vehicleSlice from "./reducers/vehicleSlice";

export const rootReducer = combineReducers({
  ui: uiSlice,
  auth: authSlice,
  referals: referalsSlice,
  vehicles: vehicleSlice
});
