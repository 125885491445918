import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "antd/dist/antd.min.css";
import "./App.css";
import MainPage from "./pages/mainPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { store } from "./redux/store/store";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  onAuthStateChanged,
} from "firebase/auth";
import { authentication } from "./firebase/config";
import { getToken } from "./utils/uiHelpers";
import { getRefIDFromFireBase } from "./redux/store/reducers/authSlice";

export type GlobalContent = {
  setUpRecaptcha: (number: string) => any;
  user?: any;
  setUser?: any;
};

export const MyGlobalContext = React.createContext<GlobalContent>({
  setUpRecaptcha,
});

export const useGlobalContext = () => React.useContext(MyGlobalContext);

function setUpRecaptcha(number: string) {
  const recapchaVerifer = new RecaptchaVerifier(
    "recaptcha-container",
    { size: "invisible" },
    authentication
  );
  recapchaVerifer.render();
  return signInWithPhoneNumber(authentication, number, recapchaVerifer);
}

function App() {
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      authentication,
      (currentUser: any) => {
        if (currentUser) {
          setUser(currentUser);
          localStorage.setItem("accessToken", currentUser.accessToken);
          localStorage.setItem("uid", currentUser.uid);
          if (getToken())
            store.dispatch(getRefIDFromFireBase({ uid: currentUser.uid }));
        } else {
          if (getToken()) {
            store.dispatch(getRefIDFromFireBase({ uid: getToken() }));
          }
        }
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Provider store={store}>
      <MyGlobalContext.Provider value={{ setUpRecaptcha, user, setUser }}>
        <BrowserRouter>
          <MainPage />
          <ToastContainer autoClose={2000} />
        </BrowserRouter>
      </MyGlobalContext.Provider>
    </Provider>
  );
}

export default App;
