import styled from "styled-components";
import {styles} from "../../styles/styles";

export const PhotoVideoBox = styled.div`
  width: 100%;

  .text {
    margin-bottom: 20px;
  }
`;

export const PhotoVideoList = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: ${({full}: { full?: boolean }) =>
          full ? "repeat(1, 1fr)" : "repeat(4, 1fr)"};
`;

export const PhotoVideoItem = styled.div`
  border: 1px ${({dashed}: { full?: boolean; dashed?: boolean }) => dashed ? "dashed" : "solid"} ${styles.mainColor};
  padding-top: ${({full}: { full?: boolean; dashed?: boolean }) =>
          full ? "25%" : "100%"};
  position: relative;
  border-radius: 10px;

  .item {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 7px;
    }

    .cover {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height */

      text-align: center;

      color: #ffffff;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(34, 34, 34, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
    }
  }

  .new-img {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 7px;

    svg {
      margin-bottom: 1px;
    }

    input {
      display: none;
    }

    .new-img-text {
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      /* identical to box height, or 118% */

      text-align: center;

      color: #ff6633;
    }
  }

  .close {
    position: absolute;
    top: -3px;
    right: -3px;
    background-color: white;
    border-radius: 4px;
    display: flex;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 12px;
`;

export const Desc = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

export const StatusBox = styled.div`
  display: flex;
  margin-top: 18px;

  .status-part {
    margin-right: 12px;
    width: 24px;
  }

  .text-part {
    width: calc(100% - 36px);
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
`;
