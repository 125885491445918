// const ENDPOINT = "http://r.rukki.pro";
// const ENDPOINT = "http://localhost:3001";
const ENDPOINT = "https://api.rukki.pro";
// const WS_URL = "ws://localhost:3001";
const WS_URL = "https://api.rukki.pro";
const BASE_URL = `${ENDPOINT}/api`;
const SEARCH_ADDRESS_URL = "https://search-maps.yandex.ru/v1/";
const GEOCODE_MAPS = "https://geocode-maps.yandex.ru/1.x/";
const YANDEX_PLACE_APIKEY = "83521ae3-4f3e-491a-b483-7ccb9b1d163e";
// process.env.REACT_APP_YANDEX_PLACE_APIKEY;

export { ENDPOINT,BASE_URL, SEARCH_ADDRESS_URL, GEOCODE_MAPS, YANDEX_PLACE_APIKEY, WS_URL };
