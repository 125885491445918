import React from "react";
import { ContentTitle } from "../../components/styles/styles";
import { VehicleWrapper } from "./styles";

import MGoBack from "../../components/styles";

const RefTree: React.FC = () => {
  return (
    <VehicleWrapper>
      <ContentTitle>
        <MGoBack />
        Дерево рефералов
      </ContentTitle>
    </VehicleWrapper>
  );
};

export default RefTree;
