import React from "react";
import { NavItem, NavsListWrapper, SidebarWrapper } from "./styles";

import { ReactComponent as OptionIcon } from "../../assets/icons/options.svg";
import { useLocation, Link } from "react-router-dom";

const navList = [
  {
    name: "Пользователи (Связь с СРМ)",
    path: "/users",
    icon: <OptionIcon />,
  },
];

const Sidebar: React.FC<any> = ({ setSwitchToAgent }) => {
  const { pathname } = useLocation();

  return (
    <SidebarWrapper>
      <NavsListWrapper>
        {navList.map(({ name, path, icon }) => (
          <NavItem key={path} active={path === pathname}>
            <Link
              to={path}
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p>{name}</p>
              <div>{icon}</div>
            </Link>
          </NavItem>
        ))}
      </NavsListWrapper>
    </SidebarWrapper>
  );
};

export default Sidebar;
