import React, {useRef, useState} from "react";
import {SearchOutlined} from "@ant-design/icons";
import type {FilterConfirmProps} from "antd/es/table/interface";
import type {InputRef} from "antd";
import {Button, Input, Space} from "antd";
import {useAppSelector} from "../../../hooks";
import {RootState} from "../../../redux/store/store";
import {Table, TableWrapper} from "./styles";
import type {ColumnType} from "antd/es/table";
// import Highlighter from "react-highlight-words";
import {useNavigate} from "react-router-dom";
import moment from "moment";

interface UsersData {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  role: string;
  city: string;
  commission: string;
}

interface Columns {
  title: string;
  dataIndex: string;
  key: string;
}

interface Props {
  usersData: UsersData[];
  // columns: Columns[];
  changePageHandler: (current: number, pageSize: number) => void;
  current: number;
  total: number;
}

const UsersTable: React.FC<Props> = ({
                                       usersData,
                                       // columns,
                                       changePageHandler,
                                       current,
                                       total,
                                     }) => {
  const {loading} = useAppSelector((state: RootState) => state.referals);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const navigate = useNavigate();

  const handleSearch = (
      selectedKeys: string[],
      confirm: (param?: FilterConfirmProps) => void,
      dataIndex: any
  ) => {
    console.log("selectedKeys[0]", selectedKeys[0]);
    console.log("dataIndex", dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex: any): ColumnType<any> => ({
    filterDropdown: (
        {
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
        <div style={{padding: 8}}>
          <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                  handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              style={{marginBottom: 8, display: "block"}}
          />
          <Space>
            <Button
                type="primary"
                onClick={() =>
                    handleSearch(selectedKeys as string[], confirm, dataIndex)
                }
                icon={<SearchOutlined/>}
                size="small"
                style={{width: 90}}
            >
              Search
            </Button>
            <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{width: 90}}
            >
              Reset
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({closeDropdown: false});
                  setSearchText((selectedKeys as string[])[0]);
                  setSearchedColumn(dataIndex);
                }}
            >
              Filter
            </Button>
          </Space>
        </div>
    ),
    filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}}/>
    ),
    onFilter: (value, record) => {
      let testRecord = record[dataIndex];
      if (testRecord === null) testRecord = "";
      return testRecord
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: "Номер",
      dataIndex: "id",
      key: "id",
      onCell: (record: any, rowIndex: any) => {
        return {
          onClick: (event: any) => navigate(`/users/${record.id}`),
        };
      },
    },
    {
      title: "Имя",
      dataIndex: "firstName",
      key: "firstName",
      onCell: (record: any, rowIndex: any) => {
        return {
          onClick: (event: any) => navigate(`/users/${record.id}`),
        };
      },
      ...getColumnSearchProps("firstName"),
    },
    {
      title: "Фамилия",
      dataIndex: "secondName",
      key: "secondName",
      onCell: (record: any, rowIndex: any) => {
        return {
          onClick: (event: any) => navigate(`/users/${record.id}`),
        };
      },
      ...getColumnSearchProps("secondName"),
    },
    {
      title: "Город",
      dataIndex: "city",
      key: "city",
      onCell: (record: any, rowIndex: any) => {
        return {
          onClick: (event: any) => navigate(`/users/${record.id}`),
        };
      },
      ...getColumnSearchProps("city"),
    },
    {
      title: "Телефон",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (_: any, record: any) => (
          <Space size="middle">
            <a href={`tel:${record.phoneNumber}`}>{record.phoneNumber}</a>
          </Space>
      ),
      ...getColumnSearchProps("phoneNumber"),
    },
    {
      title: "Роль",
      dataIndex: "role",
      key: "role",
      onCell: (record: any, rowIndex: any) => {
        return {
          onClick: (event: any) => navigate(`/users/${record.id}`),
        };
      },
      render: (text: string) => (text === "worker" ? "Исполнитель" : "Заказчик"),
    },
    {
      title: "Общая комиссия",
      dataIndex: "totalCommission",
      key: "totalCommission",
      ...getColumnSearchProps("phoneNumber"),
    },

    {
      title: "Источник",
      dataIndex: "source",
      key: "source",
      ...getColumnSearchProps("source"),
      render: (source: string | null) => (source !== null ? source : "null")
    },
    {
      title: "Дата регистрации",
      dataIndex: "createdAt",
      key: "createdAt",
      onCell: (record: any, rowIndex: any) => {
        return {
          onClick: (event: any) => navigate(`/users/${record.id}`),
        };
      },
      render: (date: string) => (moment(date).format("DD.MM.YYYY").toString()),
      ...getColumnSearchProps("createdAt"),
    }
  ];

  return (
      <TableWrapper style={{width: "100%", overflowX: "auto"}}>
        <Table
            dataSource={usersData}
            columns={columns}
            loading={loading === "pending"}
            pagination={{
              total: total,
              current: current,
              onChange: (current: any, pageSize: any) => {
                changePageHandler(current, pageSize);
              },
            }}
        />
      </TableWrapper>
  );
};

export default UsersTable;
