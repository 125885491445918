import React from "react";
import { Header } from "./styles";
import { Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "antd";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import { RootState } from "../../../redux/store/store";
import { clearParrentUser } from "../../../redux/store/reducers/referalsSlice";
interface Props {
  close: () => void;
  title?: string;
  clickHandler?: () => void;
  id?: number;
  showTitle?: boolean;
}

const UserHeader: React.FC<Props> = ({
  close,
  title,
  clickHandler,
  id,
  showTitle,
}) => {
  const { loadingParrentUser } = useAppSelector(
    (state: RootState) => state.referals
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const changeUserHandler = (id?: number) => {
    if (!id) return;
    dispatch(clearParrentUser());
    navigate(`/users/${id}`);
  };

  if (loadingParrentUser === "pending")
    return (
      <Header>
        <Skeleton.Input active />
        <CloseOutlined onClick={close} />
      </Header>
    );
  return (
    <Header>
      <Typography.Text
        style={{ color: "#FF6633", cursor: id ? "pointer" : "initial" }}
        onClick={(e) => {
          changeUserHandler(id);
        }}
      >
        {showTitle && `Агент: ${title || ""}`}
      </Typography.Text>
      <CloseOutlined onClick={close} />
    </Header>
  );
};

export default UserHeader;
