import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { menuItems } from "../../constants/translations";
import PageContainer from "../pageContainer";
import Sidebar from "../sidebar";
import {
  ContentPart,
  HelpBtn,
  SidebarContentWrapper,
  SidebarPart,
} from "./styles";
import { ReactComponent as QuestionMark } from "../../assets/icons/question_mark.svg";
import { useAppSelector } from "../../hooks";
import { RootState } from "../../redux/store/store";

const SidebarContent: React.FC<any> = ({ setSwitchToAgent }) => {
  const [sizes, setSizes] = useState({
    footerHeight: 0,
    windowHeight: 0,
    offsetTop: 0,
    pageHeight: 0,
  });
  const { pathname } = useLocation();
  const { sideBar } = useAppSelector((state: RootState) => state.ui);

  const getTitle = (path: string) => {
    path = path.slice(1);
    path = path.split("/")[0];

    return menuItems[path];
  };

  useEffect(() => {
    let body = document.body;
    let footer = document.querySelector("footer");

    setSizes({
      offsetTop: window.pageYOffset,
      footerHeight: footer?.clientHeight || 0,
      windowHeight: window.outerHeight,
      pageHeight: body.scrollHeight,
    });

    window.addEventListener("scroll", () => {
      let body = document.body;
      let footer = document.querySelector("footer");

      // const max = body.scrollHeight - window.outerHeight;
      setSizes({
        offsetTop: window.pageYOffset,
        footerHeight: footer?.clientHeight || 0,
        windowHeight: body.clientHeight,
        pageHeight: body.scrollHeight,
      });
    });
  }, []);

  return (
    <PageContainer
      title={getTitle(pathname) || "Профиль и настройки"}
      agentMode={setSwitchToAgent}
    >
      <SidebarContentWrapper>
        {/* <SidebarPart mobile={pathname === "/"}> */}
        <SidebarPart mobile={sideBar}>
          <Sidebar setSwitchToAgent={setSwitchToAgent} />
        </SidebarPart>
        <ContentPart mobile={sideBar}>
          <Outlet />
        </ContentPart>
      </SidebarContentWrapper>
      {/* <HelpBtn
        bottom={
          sizes.pageHeight - sizes.windowHeight - sizes.footerHeight / 2 <
          sizes.offsetTop
            ? sizes.offsetTop -
              (sizes.pageHeight - sizes.windowHeight - sizes.footerHeight / 2) +
              30
            : 0
        }
        onClick={() => window.open("https://t.me/RukkiPro_refagent")}
      >
        <QuestionMark />
        <span>Помощь</span>
      </HelpBtn> */}
    </PageContainer>
  );
};

export default SidebarContent;
