import { Row } from "antd";
import styled, { keyframes } from "styled-components";
// import { Button } from "../../components/form/styles";
// import { styles } from "../../components/styles/styles";

export const ProfileWrapper = styled.div`
  width: 100%;
`;

export const StyledRow = styled(Row)`
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

// export const AvatarBox = styled.div`
//   display: flex;
//   margin: 16px 0 32px;
// `;

// const animation = keyframes`
//   from{
//     transform: rotate(0deg);
//   }
//   to{
//     transform: rotate(360deg);
//   }
// `;

// export const AvatarImg = styled.div`
//   width: 80px;
//   height: 80px;
//   /* border-radius: 100%; */
//   overflow: hidden;
//   margin-right: 15px;
//   position: relative;
//   display: flex;
//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     object-position: center;
//     border-radius: 50%;
//   }

//   .loading {
//     width: 50%;
//     height: 50%;
//     border-radius: 50%;
//     border: 1px solid black;
//     border-bottom-color: transparent;
//     margin: auto;
//     animation-name: ${animation};
//     animation-duration: 1s;
//     animation-timing-function: linear;
//     animation-iteration-count: infinite;
//   }

//   .remove-img {
//     position: absolute;
//     top: 0;
//     right: 0;
//     background-color: white;
//     display: flex;
//     border-radius: 50%;
//     opacity: 0;
//     cursor: pointer;
//   }

//   &:hover {
//     .remove-img {
//       opacity: 1;
//     }
//   }
// `;

// export const AvatarData = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   .verified-user {
//     font-weight: 400;
//     font-size: 16px;
//     line-height: 20px;
//     display: flex;
//     align-items: center;
//     span {
//       width: fit-content;
//       display: block;
//       margin-right: 7px;
//     }

//     .box {
//       display: flex;
//       position: relative;
//       .sign {
//         position: absolute;
//         top: 0;
//         right: 0;
//         bottom: 0;
//         left: 0;
//         text-align: center;
//         font-size: 10px;
//         line-height: 18px;
//         vertical-align: middle;
//         color: ${styles.mainColor};
//       }
//     }

//     svg {
//       width: 18px;
//       height: 18px;
//     }
//   }
//   .registered-time {
//     font-weight: 400;
//     font-size: 14px;
//     line-height: 16px;
//     margin-top: 3px;
//   }
// `;

// export const AddImgBtn = styled.label`
//   width: 32px;
//   height: 32px;
//   position: absolute;
//   margin: auto;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background-color: rgba(255, 255, 255, 0.84);
//   display: flex;
//   border-radius: 100%;
//   svg {
//     width: 18px;
//     height: 18px;
//     margin: auto;
//   }
//   input {
//     display: none;
//   }
// `;

// export const fFormWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   flex-wrap: wrap;
// `;

// export const InputWrapper = styled.div`
//   width: ${({ threeByOne }: { threeByOne?: boolean }) =>
//     threeByOne ? "30%" : "48%"};
//   margin-bottom: 26px;
//   position: relative;

//   button {
//     outline: 0;
//   }

//   .name {
//     width: 100%;
//     height: 100%;
//     padding: 6px 0;
//     background-color: white;
//     font-weight: 400;
//     font-size: 16px;
//     line-height: 24px;
//     height: 36px;
//     .placeholder {
//       color: #9e9e9e;
//     }
//   }

//   .ant-picker,
//   .ant-picker-focused {
//     border: 0;
//     outline: 0;
//     box-shadow: 0 0 0;
//     padding: 0;
//     width: 100%;
//   }

//   .ant-select-selector,
//   .ant-select,
//   .ant-select-auto-complete,
//   .ant-select-focused,
//   .ant-select-open,
//   .ant-select-single,
//   .ant-select-show-search {
//     border: 0 !important;
//     outline: none !important;
//     box-shadow: 0;
//   }
//   .ant-select-selector {
//     /* padding: 0 !important; */
//   }

//   .ant-picker {
//     width: 0;
//     opacity: 0;
//     /* height: 30px; */
//   }

//   .ant-picker-input input {
//     /* display: none; */
//     width: 0;
//     opacity: 0;

//     /* overflow: hidden; */
//   }

//   @media only screen and (max-width: 1050px) {
//     width: 100%;
//   }
// `;

// export const DocTitle = styled.p`
//   margin-bottom: 13px;
//   margin-left: 15px;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 20px;
//   .pending {
//       color: #fec826;
//   }
//   .fulfilled {
//     /* color: #999999; */
//     color: #71C562;
//   }
//   .rejected {
//     color: #ff3358;
//   }
// `;

// export const DocsWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   flex-wrap: wrap;
// `;

// export const DocWrapper = styled.div`
//   width: 48%;
//   margin-bottom: 20px;
//   /* margin-right: 16px; */
//   display: flex;
//   align-items: center;
//   padding: 15px;
//   border-radius: 8px;
//   cursor: pointer;
//   position: relative;
//   overflow: hidden;
//   ${({ done }: { done?: boolean }) => done && "border: 1px solid black;"}

//   .background {
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     background-color: transparent;
//     width: 100%;
//     height: 100%;
//   }

//   &:hover {
//     background-color: #00000011;
//   }
//   .icon-box {
//     margin-right: 16px;
//     display: flex;
//     position: relative;
//     z-index: 2;
//   }
//   .text-part {
//     position: relative;
//     z-index: 2;

//     .title {
//       font-weight: 400;
//       font-size: 16px;
//       line-height: 24px;
//       padding-bottom: 10px;
//     }
//     .desc {
//       width: fit-content;
//       font-weight: 400;
//       font-size: 14px;
//       line-height: 16px;
//       color: rgba(0, 0, 0, 0.543846);
//       /* margin-top: 10px; */
//       display: inline-block;
//     }
//   }
//   .status-icon {
//     margin-left: auto;
//     display: flex;
//     position: relative;
//     z-index: 2;
//   }
//   @media only screen and (max-width: 1050px) {
//     width: 100%;
//   }
// `;

// export const SaveBtn = styled(Button)`
//   margin-bottom: 15px;
//   width: fit-content;
//   padding: 8px 26px;
// `;

// export const CityList = styled.div`
//   position: absolute;
//   right: 0;
//   left: 0;
//   top: 55px;
//   /* border: 1px solid black; */
//   width: 100%;
//   height: fit-content;
//   /* max-height: 200px; */
//   min-height: 0px;
//   background-color: white;
//   overflow: auto;
//   display: flex;
//   flex-wrap: wrap;
//   z-index: 4;
//   border-radius: 4px;
//   -webkit-box-shadow: 0px 3px 6px -1px rgba(34, 60, 80, 0.2);
//   -moz-box-shadow: 0px 3px 6px -1px rgba(34, 60, 80, 0.2);
//   box-shadow: 0px 3px 6px -1px rgba(34, 60, 80, 0.2);
//   /* flex-direction: column; */
//   .item {
//     border-bottom: 1px solid #ebebeb;
//     width: 100%;
//     padding: 10px;
//     cursor: pointer;
//     b {
//       margin-right: 5px;
//     }
//     &:hover {
//       background-color: #ebebeb;
//     }
//   }

//   .not-found {
//     width: 100%;
//     height: 100px;
//     background-color: lightblue;
//   }
// `;
