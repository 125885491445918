import React from "react";
import { Button } from "../../form/styles";
import { Header, PopUpBackground, PopUpBox, PopUpWrapper } from "./styles";

interface Props {
  children?: any;
  close?: any;
  button?: string;
  onSubmit?: any;
  title?: string;
  disabled?: boolean;
  middle?: boolean;
  big?: boolean;
  mb?: string;
}

const PopUpContainer: React.FC<Props> = ({
  children,
  close,
  button,
  onSubmit,
  title,
  disabled,
  middle,
  big,
  mb,
}) => {
  return (
    <PopUpWrapper>
      <PopUpBackground onClick={close} />
      <PopUpBox middle={middle} big={big}>
        <Header mb={mb}>
          <div className="title">{title}</div>
          <div className="close" onClick={close}>
            Закрыть
          </div>
        </Header>
        {children}
        {button && (
          <Button disabled={disabled} className="button" onClick={onSubmit}>
            {button}
          </Button>
        )}
      </PopUpBox>
    </PopUpWrapper>
  );
};

export default PopUpContainer;
