import React, {memo, useEffect, useState} from "react";
import {Button, FormDesc, InputPN, InputPNWrapper,} from "../form/styles";
import {Highlighted, SmallText} from "../styles/styles";
import UserAgreementPopUp from "../popUp/userAgreementPopUp";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store/store";
import {setAlternativeAuth} from "../../redux/store/reducers/authSlice";
import {isPossiblePhoneNumber} from "react-phone-number-input";
import countries from "../../constants/world.json";

interface Props {
  pN: any;
  setPN: any;
  tryToLogin: any;
  error: string;
  verifyRef: any;
  flag: boolean;
  smsRu: boolean;
  isRecapcha: boolean;
  setIsRecapcha: (value: boolean) => void;
  country: string;
  setCountry: any;
}

function LoginForm(
    {
      // phoneNumber,
      // setPhoneNumber,
      pN,
      setPN,
      tryToLogin,
      error,
      verifyRef,
      flag,
      smsRu,
      isRecapcha,
      country,
      setCountry,
    }: Props) {
  const {loadingAltAuth, alternativeAuth} = useAppSelector(
      (state: RootState) => state.auth
  );

  const [userAgreement, setUserAgreement] = useState<boolean>(false);
  // const [loader, setLoader] = useState<boolean>(false);

  const [maxLengthPN, setMaxLengthPN] = useState(0);

  const dispatch = useAppDispatch();

  const isActive = !isPossiblePhoneNumber(pN);
  // phoneNumber?.value &&
  // (uz ? phoneNumber?.value.length === 9 : phoneNumber?.value.length === 10)
  //   ? false
  //   : true;

  useEffect(() => {
    // return () => setLoader(false);
  }, []);

  return (
      <>
        <FormDesc>Введите номер телефона для авторизации</FormDesc>
        {/* {alternativeAuth && (
        <FormDesc>
          <span style={{ color: "red" }}>
            Альтернативаная авторизация через SMS.ru
          </span>
        </FormDesc>
      )} */}

        <InputPNWrapper>
          <select
              onChange={(e) => {
                setCountry(e.target.value);
              }}
              value={country}
          >
            {countries
                ?.filter(({country_id}: any) => country_id)
                ?.map(({dial_code, flag, code, alpha2}: any, index) => (
                    <option key={index} value={alpha2?.toUpperCase()}>
                      {flag} {dial_code}
                    </option>
                ))}
          </select>
          <InputPN
              // disabled
              // international
              country={country}
              placeholder="Введите номер телефона"
              value={pN}
              onChange={(e: any) => {
                setPN(e || "");

                // if (maxLengthPN > 0) {
                //   console.log("Max Length bigger than 0");

                //   if (isPossiblePhoneNumber(e || "")) {
                //     console.log("Max Length bigger than 0 and possible PN");
                //     setMaxLengthPN(e?.length);
                //     setPN(e || "");
                //   } else if (
                //     !isPossiblePhoneNumber(e || "") &&
                //     e?.length > maxLengthPN
                //   ) {
                //     console.log(
                //       "Max Length bigger than 0 and impossible PN and length lte maxLPN"
                //     );
                //     setPN(
                //       `${e?.slice(0, maxLengthPN - 1)}${e?.slice(e?.length - 1, e?.length)}` || ""
                //     );
                //   }
                // } else {
                //   console.log("this is else");

                //   if (isPossiblePhoneNumber(e || "")) {
                //     console.log("Possible PN");
                //     setMaxLengthPN(e?.length);
                //   }
                //   setPN(e || "");
                // }
              }}
              onKeyDown={(e: any) => {
                if (
                    e.key === "Enter" &&
                    !(
                        // loader ||
                        (isActive || flag || loadingAltAuth === "pending" || isRecapcha)
                    )
                ) {
                  // e.preventDefault();
                  tryToLogin();
                }
              }}
              // onValueChange={(e) => {
              //   setPhoneNumber(e);
              // }}
              // value={phoneNumber?.floatValue}
              // format={uz ? "+998 (##) ###-##-##" : "+7 (###) ###-##-##"}
              // placeholder="+7"
          />
        </InputPNWrapper>
        {error !== "" && (
            <div style={{color: "red", margin: "20px 0", fontWeight: "bold"}}>
              {error}
            </div>
        )}
        <SmallText style={{marginTop: "22px"}}>
        <span
            style={{color: "blue", cursor: "pointer"}}
            onClick={() => {
              if (!alternativeAuth) dispatch(setAlternativeAuth(true));
              else dispatch(setAlternativeAuth(false));
            }}
        >
          {!alternativeAuth
              ? "Перейти к альтернативной авторизации"
              : "Вернуться к обычной авторизации"}
        </span>
        </SmallText>
        <SmallText style={{marginTop: "32px"}}>
          Нажимая кнопку ниже, вы принимаете наше
          <Highlighted onClick={() => setUserAgreement(true)}>
            {" "}
            пользовательское соглашение
          </Highlighted>
        </SmallText>
        {/* <div id="recaptcha-container" ref={verifyRef}></div> */}
        <Button
            ref={verifyRef}
            id="recaptcha-container"
            disabled={
              // loader ||
                isActive || flag || loadingAltAuth === "pending" || isRecapcha
            }
            onClick={tryToLogin}
            style={{marginTop: "24px"}}
        >
          Войти
        </Button>
        {userAgreement && (
            <UserAgreementPopUp close={() => setUserAgreement(false)}/>
        )}
      </>
  );
}

export default memo(LoginForm);


// import React, { useState } from "react";
// import { Button, FormDesc, FormTitle } from "../form/styles";
// import { Highlighted, SmallText } from "../styles/styles";
// import Input from "../Input";
// import UserAgreementPopUp from "../popUp/userAgreementPopUp";
// import { useNavigate } from "react-router-dom";
// import { useAppDispatch } from "../../hooks";
// import { setAuth } from "../../redux/store/reducers/authSlice";
// import { ErrorText } from "./styles";

// interface Props {
//   error: string | null;
// }

// interface Login {
//   login: string;
//   password: string;
// }

// function LoginForm({ error }: Props) {
//   const [userAgreement, setUserAgreement] = useState<boolean>(false);
//   const [formData, setFormData] = useState<Login>({
//     login: "",
//     password: "",
//   });
//   const [errorAuth, setErrorAuth] = useState<string>("");

//   const navigate = useNavigate();
//   const dispatch = useAppDispatch();

//   const handleChangePassord = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { name, value } = e.target;
//     setFormData((prevState) => ({ ...prevState, [name]: value }));
//   };

//   return (
//     <form
//       onSubmit={(event) => {
//         console.log("formdata", formData);
//         event.preventDefault();
//         if (
//           process.env.REACT_APP_LOGIN === formData.login &&
//           process.env.REACT_APP_PASSWORD === formData.password
//         ) {
//           setErrorAuth("");
//           const encodedData = window.btoa(
//             `${formData.login}:${formData.password}`
//           );
//           localStorage.setItem("accessToken", encodedData);
//           setTimeout(() => {
//             dispatch(setAuth());
//           }, 0);
//           setTimeout(() => {
//             navigate("/");
//           }, 0);
//         } else {
//           setErrorAuth("Неверные логин или пароль");
//           // setFormData({
//           //   login: "",
//           //   password: "",
//           // });
//         }
//       }}
//     >
//       <FormTitle>Номер телефона</FormTitle>
//       <FormDesc>Введите логин и пароль</FormDesc>
//       <Input type="text" name="login" required onChange={handleChangePassord} />
//       <Input
//         type="password"
//         name="password"
//         required
//         renderIcon={true}
//         onChange={handleChangePassord}
//       />
//       {errorAuth !== "" && <ErrorText>Неправильный логин или пароль</ErrorText>}
//       {error !== "" && (
//         <div style={{ color: "red", margin: "20px 0", fontWeight: "bold" }}>
//           {error}
//         </div>
//       )}
//       <SmallText style={{ marginTop: "32px" }}>
//         Нажимая кнопку ниже, вы принимаете наше
//         <Highlighted onClick={() => setUserAgreement(true)}>
//           {" "}
//           пользовательское соглашение
//         </Highlighted>
//       </SmallText>
//       <Button type="submit" style={{ marginTop: "24px" }}>
//         Войти
//       </Button>
//       {userAgreement && (
//         <UserAgreementPopUp close={() => setUserAgreement(false)} />
//       )}
//     </form>
//   );
// }

// export default LoginForm;
